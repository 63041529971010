import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormDialog from './../../_presentationals/FormDialog';
import TextField from './../../_presentationals/Form/TextField';
import DateField from './../../_presentationals/Form/DateField';
import SelectField from './../../_presentationals/Form/SelectField';
import AutocompleteField from './../../_presentationals/Form/AutocompleteField';
import IconButton from './../../_presentationals/Form/IconButton';
import moneyFormat from '../../_helpers/moneyFormat';

import { alertActions, getDataFromServer } from './../../_actions';

import { recordsServices } from './../../_services';
import { errorFormat } from './../../_helpers';
import { format } from "date-fns";

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: { padding: theme.spacing(0.4) }
}));

const ERROR_MESSAGE = 'Ha ocurrido un error, por favor inténtalo de nuevo más tarde.';

const defaultValues = {
  quantity: 1,
  price: 0,
  discount: 0,
  product: '',
  productId: { value: '', label: '' },
  commentary: '',
  taxes: false,
  action: 2, type: 1, date: format(new Date(), 'yyyy/MM/dd'),
  folio: '', supplierId: { value: '', label: '' }
};

const actionOptions = [{ value: 1, label: 'Crédito' }, { value: 2, label: 'Contado' }];
const typeOptions = [
  { value: 1, label: 'Almacen' },
  { value: 2, label: 'Administrativo' },
  { value: 3, label: 'Consumible' },
  { value: 4, label: 'Vehículo' }
];
const taxesOptions = [{ value: false, label: 'NO' },{ value: true, label: 'SI' }]

const Component = props => {
	const [values,setValues] = useState(defaultValues);
  const [errors,setErrors] = useState({});
  const [rows,setRows] = useState([]);

  const classes = useStyles();

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }
  
  const handleAction = () => { 
    const url = `expenses`;
    
    recordsServices.create(url, { ...values, supplierId: values.supplierId.value, Products: rows })
      .then(data => {
        if(data.errorCode) { throw new Error(JSON.stringify(data.errorMessage)); }
        props.showAlert({ variant: 'success' });
        props.getDataFromServer();
        handleClose();
      })
      .catch(e => { 
        const tmpErrors = errorFormat(e.message);
        setErrors(tmpErrors);
        props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
  }

  const handleClose = () => { 
    setValues(defaultValues); 
    setErrors({});
    setRows([]);
    props.handleClose();
  }

  const handleAdd = e => {
    try {
      const quantity = parseFloat(values.quantity);
      const price = parseFloat(values.price);
      const discount = parseFloat(values.discount);
      const productId = values.productId.value;
      const label = values.productId.label;
      const product = values.product;
      const unit = values.productId.unit;
      

      if(isNaN(quantity) || quantity < 0) {
        throw new Error(JSON.stringify([{name: 'quantity', message: ''}]));
      }

      if(values.type === 1 && productId === '') {
        throw new Error(JSON.stringify([{name: 'product', message: ''}]));
      }

      if(values.type !== 1 && product === '') {
        throw new Error(JSON.stringify([{name: 'product', message: ''}]));
      }

      const coincidences = rows.find(el => el.productId === (values.type === 1 ? productId : product));

      if(coincidences) throw new Error(JSON.stringify([{name: 'product', message: 'Este ingrediente ya fue asignado.'}]));
      const subtotal = values.taxes ?  parseFloat(price) / 1.16 * parseFloat(quantity) : parseFloat(price) * parseFloat(quantity);;
      // const tmp = parseFloat(quantity) * parseFloat(price);
      // const totalTmp = (parseFloat(tmp) - (parseFloat(tmp) * (parseFloat(discount)/100)));
      const totalTmp = parseFloat(subtotal) - parseFloat(discount);
      const iva = values.taxes ? parseFloat(totalTmp) * .16 : 0;
      const total = parseFloat(totalTmp) + parseFloat(iva);

      setRows(rows => [ ...rows, { unit, quantity, price, discount, iva, total, productId: productId || null, name: product || label } ]);
      setErrors({});
      setValues({ ...values, quantity: 1, price: 0, discount: 0, unit: '', productId: { value: '', label: '' }, product: '' });

    } catch(e) {
      const tmpErrors = errorFormat(e.message);
      setErrors(tmpErrors);
      props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
    }
  }

  useEffect(() => {
    setRows([]);
    setValues(values => ({ ...values, quantity: 1, price: 0, discount: 0, unit: '', productId: { value: '', label: '' }, product: '' }));
  },[values.type]);

  const handleDelete = (e, {i}) => {
    const tmp = rows.map((el, index) => index !== i && el).filter(el => el);
    setRows(tmp);
  }
  
	return (
		<FormDialog 
      title='Gasto'
      width='lg'
      isOpen={props.isOpen || false}
      handleClose={handleClose}
      handleAction={handleAction}
    >
      <Grid container spacing={1}>
        <Grid item lg={12} xs={12}>
          <SelectField 
            name='action'
            label='Modalidad'
            value={values.action}
            onChange={handleChange}
            options={actionOptions}
            error={errors.action}
            size='small'
            noFormat
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <SelectField 
            name='type'
            label='Tipo'
            value={values.type}
            onChange={handleChange}
            options={typeOptions}
            error={errors.action}
            size='small'
            noFormat
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <DateField 
            name='date'
            label='Fecha'
            value={values.date}
            onChange={handleDateChange}
            error={errors.date}
            noFormat
          />
        </Grid> 
        <Grid item lg={12} xs={12}>
          <TextField
            name='folio'
            label='Número de Factura'
            value={values.folio}
            onChange={handleChange}
            size='small'
            noFormat
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <TextField
            name='commentary'
            label='Comentario'
            value={values.commentary}
            onChange={handleChange}
            size='small'
            noFormat
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <AutocompleteField
            name='supplierId'
            label='Proveedor'
            value={values.supplierId}
            onChange={handleAutocompleteChange}
            options={props.suppliers}
            error={errors.supplierId}
            size='small'
            noFormat
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <SelectField 
            name='taxes'
            label='Aplica IVA'
            value={values.taxes}
            onChange={handleChange}
            options={taxesOptions}
            error={errors.action}
            size='small'
            noFormat
          />
        </Grid>
      </Grid>
      <br/>

      <Grid container spacing={1}>
        <Grid item lg={4} xs={12}>
          {
            (values.type === 1) ?
            <AutocompleteField
              name='productId'
              label='Producto'
              variant='outlined'
              value={values.productId}
              onChange={handleAutocompleteChange}
              options={props.products}
              error={errors.productId}
              size='small'
              noFormat
            /> :
            <TextField
              name='product'
              label='Producto'
              variant='outlined'
              value={values.product}
              onChange={handleChange}
              error={errors.product}
              size='small'
              noFormat
            />
          }
        </Grid>

        <Grid item lg={2} xs={12}>
          <TextField
            name='unit'
            label='Unidad'
            variant='outlined'
            value={values.productId.unit || ''}
            onChange={handleChange}
            error={errors.unit}
            disabled
            size='small'
            noFormat
          />
        </Grid>
        <Grid item lg={1} xs={12}>
          <TextField
            name='quantity'
            label='Cant.'
            variant='outlined'
            value={values.quantity}
            onChange={handleChange}
            error={errors.quantity}
            size='small'
            noFormat
          />
        </Grid>
        <Grid item lg={2} xs={12}>
          <TextField
            name='price'
            label='Precio'
            variant='outlined'
            value={values.price}
            onChange={handleChange}
            error={errors.price}
            size='small'
            noFormat
          />
        </Grid>
        <Grid item lg={2} xs={12}>
          <TextField
            name='discount'
            label='Desc.'
            variant='outlined'
            value={values.discount}
            onChange={handleChange}
            error={errors.discount}
            size='small'
            noFormat
          />
        </Grid>
        <Grid item lg={1} xs={12} align='right'>
          <Fab color='secondary' size='small' onClick={ handleAdd } aria-label='Agregar Producto'>
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>

      <Grid container item xs={12} spacing={1}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Producto</TableCell>
              <TableCell>Unidad</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Cant.</TableCell>
              <TableCell>Desc.</TableCell>
              <TableCell>IVA</TableCell>
              <TableCell>Total</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            { 
              rows.map((el, i) => {
                return (
                <TableRow hover key={values.type === 1 ? el.productId : el.name}>
                  <TableCell>{el.name}</TableCell>
                  <TableCell>{el.unit}</TableCell>
                  <TableCell>{moneyFormat(el.price)}</TableCell>
                  <TableCell>{el.quantity}</TableCell>
                  <TableCell>{moneyFormat(el.discount)}</TableCell>
                  <TableCell>{moneyFormat(el.iva)}</TableCell>
                  <TableCell>{moneyFormat(el.total)}</TableCell>
                  <TableCell align='right'>
                    <IconButton
                      label='Eliminar'
                      action={ handleDelete } 
                      params={ { i } }
                      className={ classes.actionButton }
                      icon={<DeleteIcon fontSize='small' />}
                    />
                  </TableCell>
                </TableRow>)
              })
            }
          </TableBody>
        </Table>
      </Grid>

    </FormDialog>
   )
}

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(alertActions.show(data)),
  getDataFromServer: () => dispatch(getDataFromServer())
});

export default connect(null, mapDispatchToProps)(Component);