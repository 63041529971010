import React from 'react';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import TableSortLabel from './../helpers/TableSortLabel';

const Component = props => 
	<TableHead>
		<TableRow>
			{ props.Rows.map(el => 
				<TableCell key={el.key} align={ el.align || 'left' }>{ 
					el.sort 
						? <TableSortLabel 
								id={el.key} 
								label={el.label} 
								direction={props.direction[el.key]}
								onClick={props.handleSort}
							/>
						: el.label
				} </TableCell>
			)}
			<TableCell />
		</TableRow>
	</TableHead>

export default Component;