const sortByType = (rows, key, type, direction) => {
  switch(type){
    case 'text':
      rows.sort(function (a, b) {
        if (a[key] > b[key]) { return 1; }
        if (a[key] < b[key]) { return -1; }
        return 0;
      });
      break;
    case 'date':
    case 'number':
      rows.sort(function (a, b) {
        return b[key] - a[key];
      });
      break;
    default:
      break;
  }
  if(direction === 'desc') rows.reverse();
  return rows;
}

const filterRows = (value, keys, rows) => {
  const tmp = rows.filter(data => {
    const isValid = keys.some(key => 
      data[key].toLowerCase().includes(value.toLowerCase())
    );
    return isValid && data;
  });
  return tmp;
}

export { sortByType, filterRows };