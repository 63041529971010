import { config } from '../_helpers';
import { handleFetchResponseNoAuth } from './';

const login = ({ username, password }) => {
	const myRequest = new Request(
		`${ config.apiUrl }/auth/login`,
		{
			method: 'POST',
			headers: { 'Content-Type' : 'application/json' },
			body: JSON.stringify({ username, password })
		}
	);

	return fetch(myRequest).then(handleFetchResponseNoAuth)
	.then(res => {
		
		if(res.isError){ throw new Error(JSON.stringify(res.fields)); }

		localStorage.setItem('token', res.accessToken);
		localStorage.setItem('refresh', res.refreshToken);
		localStorage.setItem('session', JSON.stringify(res.session));

		return res.session;

	});
};

const passwordRecovery = ({ method, ...props }) => {

	let body = { method };

	switch(method) {
		case "phone":
			const { tokenId } = props;
			body = { ...body, tokenId };
			break;
		case "email":
			body = {};
			break;
		default:
			body = { ...body };
			break;
	}

	const myRequest = new Request(
		`${ config.apiUrl }/auth/password/recovery`,
		{
			method: 'POST',
			headers: { 'Content-Type' : 'application/json' },
			body: JSON.stringify(body)
		}
	);
	return fetch(myRequest).then(handleFetchResponseNoAuth)
};

const logout = () => {
	localStorage.clear();
	window.location.reload(true);
};


export const authServices = { login, logout, passwordRecovery };
