import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Table from './helpers/Table';
import TableHead from './TableHead';
import TableBody from './TableBody';

import { sortByType, filterRows } from './helpers/General';
import { createXLS, createPDF } from './../../_actions';
import { JsonToXls } from './../XLS';
import { JsonToPdf } from './../PDF';

const Component = props => {

	const [ direction, setDirection ] = useState({});
	const [ rows, setRows ] = useState([]);

	useEffect(() => {
		setRows(props.records);
	}, [props.records]);

	useEffect(() => {
		const keys = props.Rows.map(el => el.filter && el.key).filter(el => el);
    const tmp = filterRows(props.searchField, keys, props.records);
    setRows(tmp);
	}, [props.searchField]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if(props.xlsDoc){
			JsonToXls(props.title, props.Rows, rows);
			props.createXLS(false);

		}
	}, [props.xlsDoc]); // eslint-disable-line react-hooks/exhaustive-deps
	
	useEffect(() => {
		if(props.pdfDoc){
			JsonToPdf(props.title, props.Rows, rows);
			props.createPDF(false);
		}
	}, [props.pdfDoc]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleSort = key => { 
		const { sort } = props.Rows.find(el => el.key === key);
		const tmpDirection = direction[key] || 'asc';
    setDirection({ [key]: tmpDirection === 'asc' ? 'desc' : 'asc' })
		const data = sortByType(rows, key, sort, tmpDirection);
    setRows(data);
	}
  
	return (
		<Table>
			<TableHead Rows={props.Rows} handleSort={handleSort} direction={direction} />
			<TableBody 
				Rows={props.Rows} 
				records={rows} 
				direction={direction}
				route={props.route} 
				extraParams={props.extraParams}
				actions={props.actions}
				noEdit={props.noEdit}
        noDelete={props.noDelete}
			/>
		</Table>
	);
}

const mapStateToProps = state => ({
	searchField: state.searchField,
	xlsDoc: state.xlsDoc,
	pdfDoc: state.pdfDoc
});

const mapDispatchToProps = dispatch => ({
	createXLS: (value) => dispatch(createXLS(value)),
  createPDF: (value) => dispatch(createPDF(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);