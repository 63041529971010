import jsPDF from 'jspdf';
import format from 'date-fns/format';
import localeES from 'date-fns/locale/es';
import { parseISO } from 'date-fns'

import { recordsServices } from './../../_services';

const session = JSON.parse(localStorage.getItem('session'));
const BRANCH_NAME = session && session.branchName;

const PhysicalInventoryReport = async id => {
	const data = await recordsServices.get(`physicalInventories/${id}`)
  .catch(e => console.log(e.message));

	const doc = new jsPDF("l");

	const date = format(parseISO(data.createdAt), 'dd/MM/yyyy', {locale: localeES});

	header(doc,{ date, user: data.User.name, description: data.description, folio: data.folio, branchName: data.Branch.name });

	let breakLine = 47;
	let tmpBreakLine;
	
	data.InventoryControls.forEach( el => {

    tmpBreakLine = breakLine;

		const productJustify = doc.splitTextToSize(`${el.Inventory.Product.name}`, 45);
		const CladificationJustify = doc.splitTextToSize(`${el.Inventory.Product.Clasification.name}`, 45);
		
		doc.text(10, breakLine, productJustify, 'left');
		doc.text(100, breakLine, CladificationJustify, 'left');
		doc.text(130, breakLine, unitLabel(el.Inventory.Product.unit) || '-', 'left');
		doc.text(180, breakLine, el.lastStock, 'left');
		doc.text(200, breakLine, el.stock, 'left');
		doc.text(230, breakLine, `${parseFloat(el.stock) - parseFloat(el.lastStock)}` , 'left');

		doc.line(10, tmpBreakLine + 2, 280, tmpBreakLine + 2);

		breakLine = tmpBreakLine + 6;
		if(breakLine >= 200) {
			breakLine = 40;
			doc.addPage('L');
			header(doc);
		}

	});
	

	doc.output('dataurlnewwindow');
}

const header = (doc,data) => {
	//CABECERA
	doc.setFontSize(12);
	doc.setFontStyle("bold");
	doc.text("Reporte de Inventario Físico", 10, 15, 'left');
	doc.text(`${data.branchName || BRANCH_NAME}`, 10, 20, 'left');
	doc.text("Fecha y Hora de Inventario", 280, 15, 'right');
	doc.text(`${data.date}`, 280, 20, 'right');
	doc.text(`Usuario: ${data.user}`, 10,25,'left');
	doc.text(`Descripción: ${![null,''].includes(data.description) ? data.description : '-'}`, 10,30,'left');
	doc.text(data.folio, 280,30,'right');

	//TABLA
	doc.text("Producto", 10, 40, 'left');
	doc.text("Clasificación", 100, 40, 'left');
	doc.text("Unidad", 130, 40, 'left');
	doc.text("Virtual", 180, 40, 'left');
	doc.text("Real", 200, 40, 'left');
	doc.text("Diferencia", 230, 40, 'left');
	doc.line(10, 43, 280, 43);

	doc.setFontSize(8);
	doc.setFontStyle("normal");
}

function unitLabel(value) {
  switch(value) {
    case 1:
      return 'PIEZA(S)';
    case 2:
      return 'GRAMO(S)';
    case 3:
      return 'MILILITRO(S)';
    default: return 'NO DEFINIDO';
  }
}

export default PhysicalInventoryReport;