import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormDialog from './../../_presentationals/FormDialog';

const Component = props => {
  const [rows,setRows] = useState([]);

  useEffect(() => {
    props.values.id && setRows(props.values.ClosingInventories);
  }, [props.values]);

  const handleClose = () => {
    setRows([]);
    props.handleClose();
  }
  
	return (
		<FormDialog 
      title='Inventario de Cierre'
      width='sm'
      isOpen={props.isOpen || false}
      handleClose={handleClose}
      hideAction={true}
    >
      <Grid container item xs={12} spacing={1}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Producto</TableCell>
              <TableCell align='center'>Cant. Inicial</TableCell>
              <TableCell align='center'>Cant. de Cierre</TableCell>
              <TableCell align='center'>Cant. Virtual</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { 
              rows.map(el => {
                return (
                <TableRow hover key={el.id}>
                  <TableCell>{el.product}</TableCell>
                  <TableCell align='center'>{el.initialQuantity}</TableCell>
                  <TableCell align='center'>{el.quantity}</TableCell>
                  <TableCell align='center'>{el.inventoryQty}</TableCell>
                </TableRow>)
              })
            }
          </TableBody>
        </Table>
      </Grid>

    </FormDialog>
   )
}


export default Component;