import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import FingerprintIcon from '@material-ui/icons/FingerprintOutlined';

import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';
import { branchesSelectField } from './../../_helpers';

import Module from './../../_presentationals/Module';

const Rows = [
	{ label: 'Nombre', key: 'name', sort: 'text', filter: true }, 
	{ label: 'Usuario', key: 'username', sort: 'text', filter: true }, 
	{ label: 'Privilegios', key: 'role' },
  { label: 'Sucursal', key: 'branch' }
];

const roleOptions = [
	{ value: 1, label: "Comandero" },
  { value: 2, label: "Administrador" }
]

const Component = ({ getData, ...props }) => {
	const [ users, setUsers ] = useState([]);
	
	useEffect(() => {
		getData && recordsServices.get('users')
			.then(data => {
				const tmp = data.map(el => {
          const { label: branch } = branchesSelectField.find(ch => ch.value === el.branchId);
          return { ...el, role: el.role.label, branch };
        });
				setUsers(tmp);
        props.getDataFromServer();
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Module 
			route="users"
      title="usuarios"
      singular="Usuario"
      Rows={Rows}
      records={users}
      fields={[
      	{
      		icon: <AccountCircleIcon />,
      		name: 'name',
      		label: 'Nombre'
      	},
      	{
      		icon: <FingerprintIcon />,
      		name: 'username',
      		label: 'Usuario'
      	},
      	{
      		name: 'password',
      		label: 'Contraseña',
      		type: 'password',
      		noShow: 'id'
      	},
        {
          name: 'pin',
          label: 'Pin (Acceso en comandero)',
          type: 'password'
        },
      	{
      		field: 'select',
      		name: 'role',
      		label: 'Privilegios',
      		options: roleOptions
      	},
        {
          field: 'select',
          name: 'branchId',
          label: 'Sucursal',
          options: branchesSelectField
        },
        
      ]}
    />
	);
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);