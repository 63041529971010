import React from "react";

import makeStyles from '@material-ui/core/styles/makeStyles';

import Image from '../../_helpers/files/ico.png';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: theme.spacing(2),
		textAlign: 'center'
	},
	image: {
		width: 400,
		height: 400
	},
	welcome: {
		fontSize: 42
	}
}));

const Container = props => {
	const classes = useStyles();
	return(
		<div className={classes.container}>
			<img src={Image} alt="Mascota Narachips" className={classes.image} />
		</div>
	)
}

export default Container;
