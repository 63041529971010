import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
  	marginTop: 1,
	  flexGrow: 1,
	  maxHeight: document.documentElement.clientHeight - ((theme.mixins.toolbar.minHeight * 2) + 18)
  }
}));

const Component = props => {
	const classes = useStyles();
	return (
		<TableContainer className={ classes.table }>
			<Table stickyHeader size="small">
				{ props.children }
			</Table>
		</TableContainer>
	)
}

export default Component;