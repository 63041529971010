const countries = localStorage.getItem('countries') ? JSON.parse(localStorage.getItem('countries')) : [];
const branches = localStorage.getItem('branches') ? JSON.parse(localStorage.getItem('branches')) : [];

export const countriesSelectField = countries.map(({id, name}) => ({ value: id, label: name }))
export const statesSelectField = countryId => {
	const states = countries.map(({ id, states }) => 
		(parseInt(id) === parseInt(countryId)) && states.map(el => ({ value: el.id, label: el.name }))
	).filter(el => el);
	return states[0] ? states[0] : [{ value: '', label: 'No se encontraron valores para mostrar.' }];
}

export const branchesSelectField = branches.map(({id, name}) => ({ value: id, label: name }))

export const addressFormat = address => {
  
  const addressLine1 = address.addressLine1 ? `${address.addressLine1}, ` : "";
  const addressLine2 = address.addressLine2 ? `${address.addressLine2}, ` : "";
  const city = address.city ? `${address.city}, ` : "";
  const State = address.State ? `${address.State.name}, ` : "";
  const zipCode = address.zipCode ? `CP ${address.zipCode}` : "";

  return `${addressLine1}${addressLine2}${city}${State}${zipCode}`;

};

export const currencyFormat = num => (Number(num).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));

export const queryFormat = values => {
  let query = "";
  Object.keys(values).forEach(key => {
    if(![undefined,null].includes(values[key]))query += `${key}=${values[key]}&`;
  });
  return query.slice(0, -1);;
}

export const errorFormat = values => {
  let tmpErrors = {};
  JSON.parse(values).forEach(el => 
    tmpErrors = { ...tmpErrors, [el.name]: el.message }
  );
  return tmpErrors;
}

export const getQuerys = (location,query) => new URLSearchParams(location().search).get(query);