import { makeStyles } from '@material-ui/core/styles';

import backgroundImage from "../../_helpers/files/back.jpg";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: { display: 'flex' },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundImage: `url(${backgroundImage})`
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: { width: drawerWidth },
  content: { 
    flexGrow: 1
  },
  logoContainer: {

    position: 'fixed',
    paddingLeft: theme.spacing(1),
    display: 'flex',
  },
  logo: { 
    marginTop: theme.spacing(0.4),
    width: 55,
    height: 55
  },
  profileButton: { 
    padding: theme.spacing(0),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  profileMenu: {
    marginTop: theme.spacing(0), 
    padding: 0,
  },
  grow: { flexGrow: 1 },
  branch: {
    marginRight: theme.spacing(1),
    fontWeight: '700',
    color: "#fff"
  },
  userInfo: {
    width: 320, 
    padding: 0,
    '&:focus': {
      outline: 'none'
    }
  },
  imgContainer: {
    position: 'relative',
    width: '50%',
    background: '#000',
    borderRadius: 5,
    '&:hover img':{
      opacity: 0.6,
    },
    '&:hover div':{
      opacity: 1,
    },
    marginRight: theme.spacing(2)
  },
  image: {
    opacity: 1,
    display: 'block',
    width: 100,
    height: 100,
    borderRadius: 5,
    transition: '.2s ease',
    backfaceVisibility: 'hidden',
    cursor: 'pointer'
  },
  middle: {
    transition: '.5s ease',
    opacity: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: '#fff',
    cursor: 'pointer'
  }
}));

export default useStyles;