import React from "react";
import { Route, Switch } from "react-router-dom";
import { Authenticated } from "./_presentationals/Routes";
import { Unauthenticated } from "./_presentationals/Routes";

import Home from "./_containers/Home";
import Login from "./_containers/Login";
import NotFound from "./_containers/NotFound";

import User from "./_containers/User";
import Country from "./_containers/Country";
import State from "./_containers/State";
import Classification from "./_containers/Classification";
import Product from "./_containers/Product";
import Branch from "./_containers/Branch";
import PhysicalInventory from "./_containers/PhysicalInventory";
import InventoryReport from "./_containers/InventoryReport";
import Supplier from "./_containers/Supplier";
import Expense from "./_containers/Expense";
import ExpenseReport from "./_containers/ExpenseReport";
import SaleReport from "./_containers/SaleReport";
import ProductSaleReport from "./_containers/ProductSaleReport";
import ProductMovement from "./_containers/ProductMovement";
import PaymentMethod from "./_containers/PaymentMethod";
import CashClosingReport from "./_containers/CashClosingReport";
import Rotation from "./_containers/Rotation";
import SaleIngredientsReport from "./_containers/SaleIngredientsReport";
import SaleChipsReport from "./_containers/SaleChipsReport";
import SaleChipsCombinationReport from "./_containers/SaleChipsCombinationReport";
import Migration from "./_containers/Migration";
import TransactionReport from "./_containers/TransactionReport";

const PlatformRoutes = () =>
  <Switch>
    <Unauthenticated path="/public/login" component={Login} />
    <Authenticated exact path="/" component={Home} />
    <Authenticated exact path="/user" component={User} />
    <Authenticated exact path="/country" component={Country} />
    <Authenticated exact path="/country/:countryId/state" component={State} />
    <Authenticated exact path="/classification" component={Classification} />
    <Authenticated exact path="/product" component={Product} />
    <Authenticated exact path="/branch" component={Branch} />
    <Authenticated exact path="/inventory/report" component={InventoryReport} />
    <Authenticated exact path="/inventory" component={PhysicalInventory} />
    <Authenticated exact path="/supplier" component={Supplier} />
    <Authenticated exact path="/expense/report" component={ExpenseReport} />
    <Authenticated exact path="/expense" component={Expense} />
    <Authenticated exact path="/sale/report" component={SaleReport} />
    <Authenticated exact path="/sale/ingredient" component={SaleIngredientsReport} />
    <Authenticated exact path="/sale/chips/report" component={SaleChipsReport} />
    <Authenticated exact path="/sale/product/report" component={ProductSaleReport} />
    <Authenticated exact path="/sale/product/rotation" component={Rotation} />
    <Authenticated exact path="/sale/chips/combination/report" component={SaleChipsCombinationReport} />
    <Authenticated exact path="/product/movement" component={ProductMovement} />
    <Authenticated exact path="/paymentMethod" component={PaymentMethod} />
    <Authenticated exact path="/cashClosing/report" component={CashClosingReport} />
    <Authenticated exact path="/migration" component={Migration} />
    <Authenticated exact path="/transaction/report" component={TransactionReport} />
    <Route component={NotFound} />
  </Switch>;

export default PlatformRoutes;