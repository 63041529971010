import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';
import { queryFormat } from './../../_helpers/general';

import Module from './../../_presentationals/Module';
import SalesDialog from './sales.dialog';
import ClosingInventoriesDialog from './closingInventories.dialog';

import ListAltIcon from '@material-ui/icons/ListAltOutlined';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';

const Rows = [
	{ label: 'Apertura', key: 'openedAt', sort: 'date' },
	{ label: 'Cierre', key: 'clientCreatedAt', sort: 'date' },
	{ label: 'SaldoVirtual', key: 'virtualBalance', align: 'right', format: 'currency' },
	{ label: 'SaldoReal', key: 'realBalance', align: 'right', format: 'currency' },
	{ label: 'Diferencia.', key: 'difference', align: 'right', format: 'currency' },
	{ label: 'Usuario', key: 'user', sort: 'text', filter: true },
	{ label: 'Sucursal', key: 'branch', sort: 'text', filter: true }
];

const defaultDialog = [false, { id: null }];

const Component = ({ getData, ...props }) => {
	const [ values, setValues ] = useState([]);
	const [ users, setUsers ] = useState([]);
	const [ openSalesDialog, setOpenSalesDialog ] = useState(defaultDialog);
	const [ openClosingInventoriesDialog, setOpenClosingInventoriesDialog ] = useState(defaultDialog);
	
	useEffect(() => {
		getData && recordsServices.get(`cashClosings`)
			.then(data => {
				const tmp = data.map(el => ({
					...el,
					openedAt: format(new Date(el.openedAt),'yyyy/MM/dd hh:mm'), 
					clientCreatedAt: format(new Date(el.clientCreatedAt),'yyyy/MM/dd hh:mm'),
					user: el.User.name, branch: el.Branch.name
				}));
				setValues(tmp);
        props.getDataFromServer();
			})
			.catch(e => console.log(e.message));
		getData && recordsServices.get('users')
      .then(data => {
        const tmp = data.map(el => ({ value: el.id, label: el.name }));
        setUsers(tmp);
      })
      .catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleFilter = ({ start = format(new Date(),'yyyy/MM/dd'), finish = format(new Date(),'yyyy/MM/dd'), user = { value: null } }) => {
		recordsServices.get(`cashClosings?${queryFormat({ start, finish, userId: user.value })}`)
		.then(data => {
			const tmp = data.map(el => ({
				...el,
				openedAt: format(new Date(el.openedAt),'yyyy/MM/dd hh:mm'), 
				clientCreatedAt: format(new Date(el.clientCreatedAt),'yyyy/MM/dd hh:mm'),
				user: el.User.name, branch: el.Branch.name
			}));
			setValues(tmp);
		})
		.catch(e => console.log(e.message));
	};

	const handleSales = (e,{id}) => {
    recordsServices.get(`cashClosings/${id}/sales`)
      .then(data => { 
        setOpenSalesDialog([ true, { id, Sales: data } ]); 
      })
      .catch(e => console.log(e.message));
  }

	const handleClosingInventories = (e,{id}) => {
    recordsServices.get(`cashClosings/${id}/inventories`)
      .then(data => { 
        setOpenClosingInventoriesDialog([ true, { id, ClosingInventories: data } ]); 
      })
      .catch(e => console.log(e.message));
  }

  const closeSalesDialog = () => setOpenSalesDialog(defaultDialog);
  const closeClosingInventoriesDialog = () => setOpenClosingInventoriesDialog(defaultDialog);


	return (
		<Fragment>
			<Module 
				route='cashClosings'
	      title='Reporte de Cortes de Caja'
	      singular='Corte de Caja'
	      Rows={Rows}
	      records={values}
	      noCreate={true}
	      noDelete={true}
	      noEdit={true}
	      actions={[
          { icon: <ExtensionIcon/>, label: 'Inventorio', onClick: handleClosingInventories },
          { icon: <ListAltIcon/>, label: 'Ventas', onClick: handleSales }
        ]}
	      filter={[handleFilter,
	      	[
						{ field: 'date', label: 'Inicio', name: 'start' },
						{ field: 'date', label: 'Fin', name: 'finish' },
						{ field: 'auto', label: 'Usuario', name: 'user', options: users }
					]
	      ]}
	    />
			<ClosingInventoriesDialog
	      isOpen={openClosingInventoriesDialog[0]}
	      values={openClosingInventoriesDialog[1]}
	      handleClose={closeClosingInventoriesDialog}
	    />
			<SalesDialog
	      isOpen={openSalesDialog[0]}
	      values={openSalesDialog[1]}
	      handleClose={closeSalesDialog}
	    />
		</Fragment>
	);
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);