import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';
import { queryFormat } from './../../_helpers/general';

import Module from './../../_presentationals/Module';

const chips = [
	{ label: "DORITOS" , value: 15 },
	{ label: "TOSTITOS" , value: 16 },
	{ label: "RUFFLES" , value: 17 },
	{ label: "CHIPS VERDES" , value: 18 },
	{ label: "CHIPS LILAS" , value: 19 },
	{ label: "CHIPS AMARILLAS" , value: 20 },
	{ label: "TOSTITOS FH" , value: 48 },
	{ label: "CHEETOS FH" , value: 49 },
]

const Rows = [
	{ label: 'Producto', key: 'product', sort: 'text', filter: true },
	{ label: 'Papas', key: 'chips' },
	{ label: 'Cant.', key: 'qty', align: 'center' },
	{ label: 'Total', key: 'total', align: 'right' }
];

const Component = ({ getData, ...props }) => {
	const [ values, setValues ] = useState([]);
	const [ products, setProducts ] = useState([]);
	
	useEffect(() => {
		getData && recordsServices.get(`sales/chips/combinations`)
			.then(data => {
				const tmp = data.map(el => ({ ...el, chips: el.chips.join(', ') }));
				setValues(tmp);
        props.getDataFromServer();
			})
			.catch(e => console.log(e.message));

		getData && recordsServices.get('products?type=1')
      .then(data => {
        const tmp = data.map(el => ({ value: el.id, label: el.name }));
        setProducts(tmp);
      })
      .catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps
	
	const handleFilter = val => {
		const { 
			start = format(new Date(),'yyyy/MM/dd'),
			finish = format(new Date(),'yyyy/MM/dd'),
			productId, ingredientId
		} = val;
		recordsServices.get(`sales/chips/combinations?${queryFormat({ start, finish, productId, ingredientId })}`)
		.then(data => {
			const tmp = data.map(el => ({ ...el, chips: el.chips.join(', ') }));
			setValues(tmp);
		})
		.catch(e => console.log(e.message));
	};

	return (
		<Module 
			route='sales/chips/combinations'
      title='Reporte de Combinación de Papas'
      singular='Combinación de Papas'
      Rows={Rows}
      records={values}
      noCreate={true}
      noDelete={true}
      noEdit={true}
      filter={[handleFilter,
      	[
					{ field: 'date', label: 'Inicio', name: 'start' },
					{ field: 'date', label: 'Fin', name: 'finish' },
					{ field: 'select', label: 'Producto', name: 'productId', options: products },
					{ field: 'select', label: 'Incluye', name: 'ingredientId', options: chips }
				]
      ]}
    />
	);
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);