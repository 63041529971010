import React, { useState } from 'react';
import { connect } from 'react-redux';

import useStyles from "./style";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import { Link } from 'react-router-dom';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import MapIcon from '@material-ui/icons/MapOutlined';
import FingerprintIcon from '@material-ui/icons/FingerprintOutlined';
import StyleIcon from '@material-ui/icons/StyleOutlined';
import FastfoodIcon from '@material-ui/icons/FastfoodOutlined';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectoryOutlined';
import BusinessIcon from '@material-ui/icons/BusinessOutlined';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCartOutlined';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import EqualizerIcon from '@material-ui/icons/EqualizerOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToAppOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';

import { authServices } from "./../../_services";
import { getDataFromServer } from './../../_actions';
import history from './../../_helpers/history';

const fullName = localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')).name : null;

const Drawer = props => {
  const [ menuOptions, setMenuOptions ] = useState(true);
  const [ openReports, setOpenReports ] = useState(false);
  
  const classes = useStyles();

  const handleMenuOption = () => {
    setMenuOptions(!menuOptions);
  }

  const handleLogout = () => {
    authServices.logout();
  }

  const handleClick = (e, url) => { 
    history.push(url);
    props.getDataFromServer(true); 
  }

  const handleOpenReports = e => {
    e.preventDefault();
    setOpenReports(!openReports);
  }

  return (
    <div>
      <Hidden xsDown implementation="js">
        <div className={classes.toolbar} />
      </Hidden>
      <Divider/>

      <Hidden smUp implementation="css" className={classes.phoneMenu}>
        <Grid container item justify='center' xs={12}>
          <Avatar
            alt="Profile"
            src={ props.img }
            className={ classes.phoneAvatar }
          />
        </Grid>
      </Hidden>
      <Hidden smUp implementation="css">
        <Button 
          fullWidth 
          size="large"
          onClick={handleMenuOption}
          classes={{
            root: classes.rootPhoneMenuButton,
            label: classes.labelPhoneMenuButton
          }} 
        >
          { fullName }
          <ArrowDropDownIcon fontSize="small" />
        </Button>
      </Hidden>
      { menuOptions ?
        <List>

          <ListItem button onClick={handleOpenReports}>

              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText primary="Reportes" />
              {openReports ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openReports} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <LinkButton 
                  handleClick={handleClick} 
                  url='/cashClosing/report' 
                  label='Cortes de Caja' 
                />
                <LinkButton 
                  handleClick={handleClick} 
                  url='/transaction/report' 
                  label='Transacciones' 
                />
                <LinkButton 
                  handleClick={handleClick} 
                  url='/sale/report' 
                  label='Ventas' 
                />
                <LinkButton 
                  handleClick={handleClick} 
                  url='/sale/product/report' 
                  label='Ventas Por Producto' 
                />
                {
                <LinkButton 
                  handleClick={handleClick} 
                  url='/sale/chips/combination/report' 
                  label='Combinación de Papas' 
                />
                /*<LinkButton 
                  handleClick={handleClick} 
                  url='/sale/chips/report' 
                  label='Rotación de Papas' 
                />*/}
                <LinkButton 
                  handleClick={handleClick} 
                  url='/sale/product/rotation' 
                  label='Rotación Por Producto' 
                />
                <LinkButton 
                  handleClick={handleClick} 
                  url='/product/movement' 
                  label='Movimientos por Producto' 
                />
                <LinkButton 
                  handleClick={handleClick} 
                  url='/expense/report' 
                  label='Gastos' 
                /> 
                <LinkButton 
                  handleClick={handleClick} 
                  url='/inventory/report' 
                  label='Inventario' 
                /> 
              </List>
            </Collapse>
          <Divider />
          <LinkButton 
            handleClick={handleClick} 
            icon={<AddShoppingCartIcon />} 
            url='/expense' 
            label='Gastos' 
          />
          <LinkButton 
            handleClick={handleClick} 
            icon={<ExtensionIcon />} 
            url='/inventory' 
            label='Inventario Físico' 
          />
          <LinkButton 
            handleClick={handleClick} 
            icon={<LocalShippingIcon />} 
            url='/migration' 
            label='Traspasos' 
          />
          <Divider />
          <LinkButton 
            handleClick={handleClick} 
            icon={<FastfoodIcon />} 
            url='/product' 
            label='Productos' 
          />
          <LinkButton 
            handleClick={handleClick} 
            icon={<StyleIcon />} 
            url='/classification' 
            label='Clasificaciones' 
          />
          <LinkButton 
            handleClick={handleClick} 
            icon={<BusinessIcon />} 
            url='/supplier' 
            label='Proveedores' 
          />
          <Divider />
          <LinkButton 
            handleClick={handleClick} 
            icon={<PaymentIcon />} 
            url='/paymentMethod' 
            label='Métodos de Pago' 
          />
          <LinkButton 
            handleClick={handleClick} 
            icon={<FingerprintIcon />} 
            url='/user' 
            label='Usuarios' 
          />
          <Divider />
          <LinkButton 
            handleClick={handleClick} 
            icon={<StoreMallDirectoryIcon />} 
            url='/branch' 
            label='Sucursales' 
          />
          <LinkButton 
            handleClick={handleClick} 
            icon={<MapIcon />} 
            url='/country' 
            label='Países y Estados' 
          />        
        </List>
        :
        <List>
          <ListItem button component={Link} to="/">
            <ListItemIcon><AccountCircleIcon /></ListItemIcon>
            <ListItemText primary="Mi Cuenta" />
          </ListItem>
          <ListItem button component={Link} to="/">
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary="Preferencias" />
          </ListItem>
          <Divider />
          <ListItem button onClick={handleLogout}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary="Cerrar Sesión" />
          </ListItem>
          <Divider />
        </List>
        }
    </div>
  )
}

const LinkButton = props => {
  const handleClick = e => { props.handleClick(e, props.url); }
  return (
    <ListItem button onClick={handleClick}>
      { props.icon && <ListItemIcon>{props.icon}</ListItemIcon> }
      <ListItemText primary={props.label} />
    </ListItem>
  );
};
const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(null, mapDispatchToProps)(Drawer);