import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasketOutlined';
import RoomServiceIcon from '@material-ui/icons/RoomServiceOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOnOutlined';
import LocalDiningIcon from '@material-ui/icons/LocalDiningOutlined';
import CameraAltIcon from '@material-ui/icons/CameraAltOutlined';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';

import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';

import Module from './../../_presentationals/Module';

import UnitsForm from './units.form';
import IngredientForm from './ingredient.form';
import PictureForm from './picture.form';

const Rows = [
	{ label: 'Nombre', key: 'name', sort: 'text', filter: true }, 
	{ label: 'Clasificación', key: 'clasificationLabel', sort: 'text' },
  { label: 'Tipo', key: 'typeLabel', sort: 'text' },
  { label: 'Precio', key: 'price', align: 'right', format: 'currency' },
  { label: 'Costo', key: 'cost', align: 'right', format: 'currency' },
  { label: 'Maneja Inventario', key: 'inventory' }
];

const typeOptions = [
  { value: 1, label:'Elaborado' },
  { value: 2, label:'Final' },
  { value: 3, label:'Ingrediente' }
];

const inventoryOptions = [
  { value: 0, label:'NO' },
  { value: 1, label:'SI' },
];

const unitOptions = [
  { value: 1, label:'PIEZA' },
  { value: 2, label:'GRAMO' },
  { value: 3, label:'MILILITRO' },
];

const defaultDialogs = [ false, { id: null } ];

const Component = ({ getData, ...props }) => {
	const [ values, setValues ] = useState([]);
  const [ ingredientOptions, setIngredientOptions ] = useState([]);
  const [ clasificationOptions, setClasificationOptions ] = useState([]);
  const [ openIngredientForm, setOpenIngredientForm ] = useState(defaultDialogs);
  const [ openPictureForm, setOpenPictureForm ] = useState(defaultDialogs);
  const [ openUnitsForm, setOpenUnitsForm ] = useState(defaultDialogs);

  useEffect(() => {
    recordsServices.get('clasifications')
      .then(data => {
        const tmp = data.map(el => ({ value: el.id, label: el.name }));
        setClasificationOptions(tmp);
      })
      .catch(e => console.log(e.message));
    recordsServices.get('products?type=3')
      .then(data => {
        const tmp = data.map(el => ({ value: el.id, label: el.name }));
        setIngredientOptions(tmp);
      })
      .catch(e => console.log(e.message));
  }, []);
	
	useEffect(() => {
		getData && recordsServices.get('products')
			.then(data => {

        const tmp = data.map(el => {
          const { label: typeLabel } = typeOptions.find(ch => ch.value === el.type);
          return { ...el, typeLabel, clasificationLabel: el.Clasification.name, inventory: el.inventory ? 'SI' : 'NO'  };
        });
				setValues(tmp);
        props.getDataFromServer();
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUnits = (e, { id }) => {
    recordsServices.get(`products/${id}/units`)
      .then(data => { 
        setOpenUnitsForm([ true, { id, ...data } ]); 
      })
      .catch(e => console.log(e.message));
  }

  const handleIngredients = (e, { id }) => {
    recordsServices.get(`products/${id}/ingredients`)
      .then(data => { 
        setOpenIngredientForm([ true, { id, ...data } ]); 
      })
      .catch(e => console.log(e.message));
  }

  const closeIngredientForm = () => { setOpenIngredientForm(defaultDialogs); }
  const closeUnitForm = () => { setOpenUnitsForm(defaultDialogs); }

  const handlePicture = (e, { id }) => {
    recordsServices.get(`products/${id}/files`)
      .then(data => { 
        setOpenPictureForm([ true, { id, ...data } ]); 
      })
      .catch(e => console.log(e.message));
  }

  const closePictureForm = () => { setOpenPictureForm(defaultDialogs); }

	return (
    <Fragment>
  		<Module 
  			route='products'
        title='productos'
        singular='Producto'
        Rows={Rows}
        records={values}
        actions={[
          { icon: <LayersOutlinedIcon />, label: 'Unidades', onClick: handleUnits },
          { icon: <ShoppingBasketIcon />, label: 'Ingredientes', onClick: handleIngredients },
          { icon: <CameraAltIcon />, label: 'Imagen', onClick: handlePicture }
        ]}
        fields={[
        	{ name: 'name', label: 'Nombre', icon: <RoomServiceIcon /> },
        	{ 
            name: 'clasificationId', 
            label: 'Clasificación',
            field: 'select',
            options: clasificationOptions
          },
          { 
            icon: <LocalDiningIcon />,
            name: 'type', 
            label: 'Tipo',
            field: 'select',
            options: typeOptions
          },
          { name: 'price', label: 'Precio', icon: <MonetizationOnIcon /> },
          { name: 'cost', label: 'Costo' },
          { name: 'inventory', label: 'Maneja Inventario', field: 'select', options: inventoryOptions },
          { name: 'unit', label: 'Unidad', field: 'select', options: unitOptions }
        ]}
      />
      <UnitsForm 
        isOpen={openUnitsForm[0]} 
        values={openUnitsForm[1]} 
        handleClose={closeUnitForm}
      />
      <IngredientForm 
        isOpen={openIngredientForm[0]} 
        values={openIngredientForm[1]} 
        handleClose={closeIngredientForm}
        ingredients={ingredientOptions}
      />
      <PictureForm 
        isOpen={openPictureForm[0]} 
        values={openPictureForm[1]} 
        handleClose={closePictureForm}
      />
    </Fragment>
	);
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);