import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';
import { queryFormat } from './../../_helpers/general';
import moneyFormat from './../../_helpers/moneyFormat';

import Module from './../../_presentationals/Module';
import ProductsDialog from './products.dialog';

import ListAltIcon from '@material-ui/icons/ListAltOutlined';

const Rows = [
	{ label: 'Fecha', key: 'clientCreatedAt', sort: 'date', filter: true },
	{ label: 'Folio', key: 'folio', sort: 'text', filter: true },
	{ label: 'Nombre', key: 'name' },
	{ label: 'Importe', key: 'total', align: 'right', format: 'currency' },
	{ label: 'Estatus', key: 'status', sort: 'text' },
	{ label: 'Usuario', key: 'user', sort: 'text', filter: true },
	{ label: 'Sucursal', key: 'branch', sort: 'text', filter: true }
];

const defaultDialog = [false, { id: null }];

const Component = ({ getData, ...props }) => {
	const [ values, setValues ] = useState([]);
	const [ users, setUsers ] = useState([]);
	const [ openProductsDialog, setOpenProductsDialog ] = useState(defaultDialog);
	const [ amount, setAmount ] = useState(0);
	
	useEffect(() => {
		getData && recordsServices.get(`sales`)
			.then(data => {
				const tmp = data.map(el => ({
					...el, clientCreatedAt: format(new Date(el.clientCreatedAt),'yyyy/MM/dd HH:mm'),
					user: el.User ? el.User.name : '-', status: el.status ? 'Activo' : 'Cancelado', branch: el.Branch.name,
					total: el.status ? parseFloat(el.total) : 0
				}));
				const total = data.reduce((total = 0,el) => (parseFloat(total) + (el.status ? parseFloat(el.total) : 0)),0);
				setValues(tmp);
				setAmount(total);
        props.getDataFromServer();
			})
			.catch(e => console.log(e.message));
		getData && recordsServices.get('users')
      .then(data => {
        const tmp = data.map(el => ({ value: el.id, label: el.name }));
        setUsers(tmp);
      })
      .catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleFilter = ({ start = format(new Date(),'yyyy/MM/dd'), finish = format(new Date(),'yyyy/MM/dd'), user = { value: null } }) => {
		recordsServices.get(`sales?${queryFormat({ start, finish, userId: user.value })}`)
		.then(data => {
			const tmp = data.map(el => ({
				...el, clientCreatedAt: format(new Date(el.clientCreatedAt),'yyyy/MM/dd HH:mm'),
				user: el.User ? el.User.name : '-', status: el.status ? 'Activo' : 'Cancelado', branch: el.Branch.name,
				total: el.status ? parseFloat(el.total) : 0
			}));
			const total = data.reduce((total = 0,el) => (parseFloat(total) + (el.status ? parseFloat(el.total) : 0)),0);
			setValues(tmp);
			setAmount(total);
		})
		.catch(e => console.log(e.message));
	};

	const handleProducts = (e,{id}) => {
    recordsServices.get(`sales/${id}/products`)
      .then(data => { 
      	const tmp = data.map(el => ({
      		...el, product: el.Product.name,
      		total: (parseFloat(el.unitPrice) * parseFloat(el.qty))
      	}));
        setOpenProductsDialog([ true, { id, Products: tmp } ]); 
      })
      .catch(e => console.log(e.message));
  }

  const closeProductsDialog = () => setOpenProductsDialog(defaultDialog);


	return (
		<Fragment>
			<Module 
				route='sales'
	      title='Reporte de Ventas'
	      singular='Venta'
	      subTitle={`Total: $ ${moneyFormat(amount)}`}
	      Rows={Rows}
	      records={values}
	      noCreate={true}
	      noDelete={true}
	      noEdit={true}
	      actions={[
          { icon: <ListAltIcon/>, label: 'Productos', onClick: handleProducts }
        ]}
	      filter={[handleFilter,
	      	[
						{ field: 'date', label: 'Inicio', name: 'start' },
						{ field: 'date', label: 'Fin', name: 'finish' },
						{ field: 'auto', label: 'Usuario', name: 'user', options: users }
					]
	      ]}
	    />
			<ProductsDialog
	      isOpen={openProductsDialog[0]}
	      values={openProductsDialog[1]}
	      handleClose={closeProductsDialog}
	    />
		</Fragment>
	);
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);