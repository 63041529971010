import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormDialog from './../../_presentationals/FormDialog';
import moneyFormat from '../../_helpers/moneyFormat';

const Component = props => {
  const [rows,setRows] = useState([]);

  useEffect(() => {
    props.values.id && setRows(props.values.Products);
  }, [props.values]);

  const handleClose = () => {
    setRows([]);
    props.handleClose();
  }
  
	return (
		<FormDialog 
      title='Productos'
      width='sm'
      isOpen={props.isOpen || false}
      handleClose={handleClose}
      hideAction={true}
    >
      <Grid container item xs={12} spacing={1}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Producto</TableCell>
              <TableCell>Cant.</TableCell>
              <TableCell>Uni.</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Desc.</TableCell>
              <TableCell>IVA</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { 
              rows.map(el => {
                return (
                <TableRow hover key={el.name}>
                  <TableCell>{el.name}</TableCell>
                  <TableCell>{el.quantity}</TableCell>
                  <TableCell>{el.unit}</TableCell>
                  <TableCell>{moneyFormat(el.price)}</TableCell>
                  <TableCell>{el.discount}</TableCell>
                  <TableCell>{moneyFormat(el.iva)}</TableCell>
                  <TableCell>{moneyFormat(el.total)}</TableCell>
                </TableRow>)
              })
            }
          </TableBody>
        </Table>
      </Grid>

    </FormDialog>
   )
}


export default Component;