// import React from "react";
import XLSX from 'xlsx';
// import { format } from 'date-fns';
// import localeES from 'date-fns/locale/es';
import dateFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO'

export const JsonToXls = (title, keys, data) => {
	const filename = title + ".xlsx";
	const wb = XLSX.utils.book_new();
	
	const header = keys.map(el => el.label);
	
	let ws = XLSX.utils.json_to_sheet([], { header, skipHeader: true });
	
	const currecyFormat = ['currency', 'balance'];

	const tmp = data.map(el => {
		const response = keys.reduce( (newObject = {},{ key, label, format }) => {
			const value = (currecyFormat.includes(format)) 
				? parseFloat(el[key]) 
				: format === 'dateTime'
					? dateFormat(parseISO(el[key]), 'dd/MM/yyyy HH:mm:ss')
					: el[key];

			return newObject = { ...newObject, [label]: value };
		},{});
		return response;
	});

	XLSX.utils.sheet_add_json(ws, tmp, { skipHeader: false, origin: "A1"});

	const ws_name = 'Page1';

	XLSX.utils.book_append_sheet(wb, ws, ws_name);
	XLSX.writeFile(wb, filename);
}