import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import { format } from 'date-fns';
import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';
import moneyFormat from '../../_helpers/moneyFormat';

import PaymentIcon from '@material-ui/icons/PaymentOutlined';
import ListAltIcon from '@material-ui/icons/ListAltOutlined';

import Module from './../../_presentationals/Module';

import PaymentForm from '../Expense/payment.form';
import ProductsDialog from '../Expense/products.dialog';

const Rows = [
	{ label: 'Fecha', key: 'date', sort: 'date' }, 
	{ label: 'Tipo', key: 'type' },
  { label: 'Método', key: 'action' },
  { label: 'Factura', key: 'folio', filter: true },
  { label: 'Comentario', key: 'commentary' },
  { label: 'Proveedor', key: 'supplier', filter: true },
  { label: 'Subtotal', key: 'subtotal', align: 'right' },
  { label: 'IVA', key: 'iva', align: 'right' },
  { label: 'Total', key: 'total', align: 'right' },
  { label: 'Abonado', key: 'payments', align: 'right' },
  { label: 'Saldo', key: 'balance', align: 'right' }
];

const defaultDialog = [false, { id: null }];


const Component = ({ getData, ...props }) => {
	const [ values, setValues ] = useState([]);
  const [ openPaymentForm, setOpenPaymentForm ] = useState(defaultDialog);
  const [ openProductsDialog, setOpenProductsDialog ] = useState(defaultDialog);

  useEffect(() => {
    getData && recordsServices.get('expenses?report=true')
      .then(data => {
        const tmp = data.map(el => ({
          ...el, date: format(new Date(el.createdAt),'yyyy/MM/dd'), supplier: el.Supplier.name,
          subtotal: moneyFormat(el.subtotal),
          iva: moneyFormat(el.iva),
          total: moneyFormat(el.total),
          payments: moneyFormat(el.payments),
          balance: moneyFormat(el.balance)
        }));
        setValues(tmp);
        props.getDataFromServer();
      })
      .catch(e => console.log(e.message));
  }, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

  const closePaymentForm = () => setOpenPaymentForm(defaultDialog);
  const closeProductsDialog = () => setOpenProductsDialog(defaultDialog);
  
  const handlePayment = (e,{id}) => {
    recordsServices.get(`expenses/${id}/payments`)
      .then(data => { 
        setOpenPaymentForm([ true, { id, Payments: data } ]); 
      })
      .catch(e => console.log(e.message));
  }

  const handleProducts = (e,{id}) => {
    recordsServices.get(`expenses/${id}/products`)
      .then(data => { 
        setOpenProductsDialog([ true, { id, Products: data } ]); 
      })
      .catch(e => console.log(e.message));
  }

	return (
    <Fragment>
  		<Module 
  			route='expenses'
        title='Reporte de Gastos'
        singular='Gasto'
        Rows={Rows}
        records={values}
        noCreate={true}
        noDelete={true}
        noEdit={true}
        actions={[
          { icon: <ListAltIcon/>, label: 'Productos', onClick: handleProducts },
          { icon: <PaymentIcon/>, label: 'Pagos', onClick: handlePayment }
        ]}
      />
      <PaymentForm
        isOpen={openPaymentForm[0]}
        values={openPaymentForm[1]}
        handleClose={closePaymentForm}
        report={true}
      />
      <ProductsDialog
        isOpen={openProductsDialog[0]}
        values={openProductsDialog[1]}
        handleClose={closeProductsDialog}
      />
    </Fragment>
	);
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);