import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';

import Module from './../../_presentationals/Module';
import moneyFormat from '../../_helpers/moneyFormat';

const Rows = [
	{ label: 'Producto', key: 'product', sort: 'text', filter: true }, 
	{ label: 'Clasificación', key: 'clasification', sort: 'text', filter: true },
  { label: 'Cant.', key: 'quantity', align: 'center' },
  { label: 'Unidad', key: 'unit', align: 'center' },
  { label: 'Costo', key: 'cost', align: 'right', format: 'currency' },
  { label: 'Inversión', key: 'investment', align: 'right', format: 'currency' },
  { label: 'Sucursal', key: 'branch', sort: 'text', filter: true }
];

const Component = ({ getData, ...props }) => {
	const [ values, setValues ] = useState([]);
  // const [ amount, setAmount ] = useState(0);
	
	useEffect(() => {
		getData && recordsServices.get('inventories')
			.then(data => {
        const tmp = data.map(el => ({
          ...el, cost: moneyFormat(el.cost), investment: moneyFormat(el.investment), unit: unitLabel(el.unit)
        }));
				// const total = data.reduce((total = 0,el) => (parseFloat(total) + parseFloat(el.investment)),0);
        setValues(tmp);
        // setAmount(total);
        props.getDataFromServer();
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Module 
			route='inventories'
      title='Inventarios'
      singular='Inventario'
      Rows={Rows}
      records={values}
      noCreate={true}
      noDelete={true}
      noEdit={true}
    />
	);
}

function unitLabel(value) {
  switch(value) {
    case 1:
      return 'PIEZA(S)';
    case 2:
      return 'GRAMO(S)';
    case 3:
      return 'MILILITRO(S)';
    default: return 'NO DEFINIDO';
  }
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);