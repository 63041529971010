import { alertConstants } from '../_constants';

const defaultValues = {
  isOpen: false,
  time: 5000,
  variant: 'default',
  message: ''
}

export const alert = (state = defaultValues, action) => {
  switch (action.type) {
    case alertConstants.SHOW:
      return { ...defaultValues, ...action.payload, isOpen: true };
   case alertConstants.CLEAR:
      return defaultValues;  
    default:
      return state

  }
}
