import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import { format } from 'date-fns';
import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';

import Module from './../../_presentationals/Module';
import InventoryPdf from './inventory.pdf';

import InventoryForm from './inventory.form';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', sort: 'date' }, 
	{ label: 'Folio', key: 'folio', sort: 'text', filter: true },
  { label: 'Descripción', key: 'description' },
  { label: 'Usuario', key: 'user', filter: true }
];


const Component = ({ getData, ...props }) => {
	const [ values, setValues ] = useState([]);
  const [ products, setProducts ] = useState([]);
  const [ openInventoryForm, setOpenInventoryForm ] = useState(false);

  useEffect(() => {
    recordsServices.get('products?inventory=true')
      .then(data => {
        const tmp = data.map(el => ({ value: el.id, label: el.name, unit: unitLabel(el.unit) }));
        setProducts(tmp);
      })
      .catch(e => console.log(e.message));
  }, []);

  useEffect(() => {
    getData && recordsServices.get('physicalInventories')
      .then(data => {
        const tmp = data.map(el => ({
          ...el, createdAt: format(new Date(el.createdAt),'yyyy/MM/dd'), user: el.User.name
        }));
        setValues(tmp);
        props.getDataFromServer();
      })
      .catch(e => console.log(e.message));
  }, [getData]); // eslint-disable-line react-hooks/exhaustive-deps


  const create = () => setOpenInventoryForm(true);
  const closeInventoryForm = () => { setOpenInventoryForm(false); }
  
  const handleReport = (e,{id}) => InventoryPdf(id);
  
	return (
    <Fragment>
  		<Module 
  			route='physicalInventories'
        title='Inventario Físico'
        singular='Inventario'
        Rows={Rows}
        records={values}
        actionCreate={create}
        noEdit={true}
        noDelete={true}
        actions={[
          { icon: <PictureAsPdfIcon/>, label: 'Reporte', onClick: handleReport }
        ]}
      />
      <InventoryForm 
        isOpen={openInventoryForm} 
        handleClose={closeInventoryForm}
        products={products}
      />
    </Fragment>
	);
}

function unitLabel(value) {
  switch(value) {
    case 1:
      return 'PIEZA(S)';
    case 2:
      return 'GRAMO(S)';
    case 3:
      return 'MILILITRO(S)';
    default: return 'NO DEFINIDO';
  }
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);