import React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { DialogTitle, DialogContent, DialogActions } from './style';

const Component = props => 
  <Dialog
   open={ props.isOpen }
   onClose={ props.handleClose }
   fullWidth={ true }
   fullScreen={ props.fullScreen || false }
   maxWidth = { props.width || 'sm' }
   disableEscapeKeyDown={false}
   disableBackdropClick={false}  
  >
    { props.title && <DialogTitle children={props.title} /> }
    <DialogContent>{ props.children }</DialogContent>
    <DialogActions>
      { !props.hideClose && <Button onClick={ props.handleClose }>Cerrar</Button> }
      { !props.hideAction && (
        <Button onClick={ props.handleAction } color="primary">
          { props.actionLabel || "Guardar" }
        </Button>
      )}
    </DialogActions>
  </Dialog>

export default Component;