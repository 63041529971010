import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormDialog from './../../_presentationals/FormDialog';
import moneyFormat from '../../_helpers/moneyFormat';

const status = ['Cancelado','Vendido'];

const Component = props => {
  const [rows,setRows] = useState([]);

  useEffect(() => {
    props.values.id && setRows(props.values.Products);
  }, [props.values]);

  const handleClose = () => {
    setRows([]);
    props.handleClose();
  }

	return (
		<FormDialog 
      title='Productos'
      width='sm'
      isOpen={props.isOpen || false}
      handleClose={handleClose}
      hideAction={true}
    >
      <Grid container item xs={12} spacing={1}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Producto</TableCell>
              <TableCell align='right'>Precio</TableCell>
              <TableCell align='center'>Cant.</TableCell>
              <TableCell align='right'>Total</TableCell>
              <TableCell>Estatus</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { 
              rows.map(el => {
                return (
                <TableRow hover key={el.id}>
                  <TableCell>{el.product}</TableCell>
                  <TableCell align='right'>{moneyFormat(el.unitPrice)}</TableCell>
                  <TableCell align='center'>{el.qty}</TableCell>
                  <TableCell align='right'>{moneyFormat(el.total)}</TableCell>
                  <TableCell>{status[parseInt(el.status)]}</TableCell>
                </TableRow>)
              })
            }
          </TableBody>
        </Table>
      </Grid>

    </FormDialog>
   )
}


export default Component;