import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';


const Component = props => 
	<TableCell align={props.align || 'left'} variant="body" >
		<Typography variant="body2" noWrap={props.noWrap}>
			{props.children}
		</Typography>
	</TableCell>

export default Component;