import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { DropzoneArea } from 'material-ui-dropzone';

import FormDialog from './../../_presentationals/FormDialog';

import { alertActions } from './../../_actions';

import { recordsServices } from './../../_services';

const ERROR_MESSAGE = 'Ha ocurrido un error, por favor inténtalo de nuevo más tarde.';

const defaultValues = { image: null };
const fileLimit = 5000000;

const Component = props => {

	const [ values, setValues ] = useState(defaultValues);
  
	useEffect(() => {
		(props.values.image && props.values.id) && setValues({ image: props.values.image });
	}, [props.values]);

	const handleChange = async files => {
    const base64 = files[0] ? await toBase64(files[0]) : '';
    setValues({ image: base64 });
  }

  const handleAction = () => { 
    const url = `products/${props.values.id}/files`;
    recordsServices.update(url, values)
      .then(data => {
        if(data.errorCode) { throw new Error(data.errorMessage); }
        props.showAlert({ variant: 'success' });
        handleClose();
      })
      .catch(e => { 
        props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
  }

  const handleClose = () => { 
    setValues(defaultValues); 
    props.handleClose();
  }
  
	return (
		<FormDialog
      title='Imagen | Formato PNG | 5MB Max.'
      isOpen={props.isOpen || false}
      handleClose={handleClose}
      handleAction={handleAction}
    >
    	<DropzoneArea 
        onChange={handleChange} 
        acceptedFiles={ ['image/png'] }
        filesLimit={1}
        maxFileSize={fileLimit}
        dropzoneText='Arrastre y suelte una imagen aquí o haga clic.'
        initialFiles={props.values.image ? [props.values.image] : []}
        showAlerts={false}
      />
    </FormDialog>
   )
}

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(alertActions.show(data))
});

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export default connect(null, mapDispatchToProps)(Component);