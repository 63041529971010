import makeStyles from '@material-ui/core/styles/makeStyles';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		padding: theme.spacing(0.5, 1.5, 1, 1.5),
	  borderBottom: '1px solid #e0e0e0', 
	  position: 'fixed',
	  zIndex: 1000,
	  backgroundColor: "#fff",
	  [theme.breakpoints.up('sm')]: {
	    width: `calc(100% - ${drawerWidth}px)`,
	  },
	  [theme.breakpoints.down('xs')]: {
	  	padding: theme.spacing(1, 1.5, 1, 1.5),
      width: '100%',
      height: 56, //48
    },
	},
	marginRight: { marginRight: theme.spacing(1) },
	paddingX: {
		paddingLeft: theme.spacing(1.6),
		paddingRight: theme.spacing(1.6),
	},
	toolbar: theme.mixins.toolbar,
}));

export default useStyles;