import React from "react";
import { connect } from 'react-redux';

import SnackbarUI from '@material-ui/core/Snackbar';

import Content from './content';
import { alertActions } from './../../_actions';

import useStyles from './style';
// 'success', 'warning', 'error', 'info', 'default'
const Component = props => {

  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    props.clearAlert();
  };
  
	return (
		<SnackbarUI
			anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      className={ classes.snackbar}
      open={ props.Options.isOpen }
      onClose={handleClose}
      autoHideDuration={ props.Options.time || 5000 }
		>
			<Content
        onClose={handleClose}
        variant={ props.Options.variant }
        message={ props.Options.message || "Petición realizada con éxito." }
      />
		</SnackbarUI>
	);

}

const mapStateToProps = state => ({ 
  Options: state.alert 
});

const mapDispatchToProps = dispatch => ({
  clearAlert: () => dispatch(alertActions.clear())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);