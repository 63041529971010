import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom'

import MenuMUI from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import indigo from '@material-ui/core/colors/indigo';
import green from '@material-ui/core/colors/green';
import pink from '@material-ui/core/colors/pink';

import { withStyles } from '@material-ui/core/styles';

import useStyles from "./style";
import { alertActions, getDataFromServer } from './../../_actions';
import { recordsServices, authServices } from "./../../_services";
import SelectField from './../../_presentationals/Form/SelectField';
import { branchesSelectField } from './../../_helpers/general';

const fullName = localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')).name : null;
const sessionBranchId = localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')).branchId : null;
const master = localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')).master : null;

const AccountButton = withStyles(theme => ({
  root: {
    color: '#fff',
    backgroundColor: indigo[500],
    '&:hover': { backgroundColor: indigo[600] }
  },
}))(Button);

const PreferencesButton = withStyles(theme => ({
  root: {
    color: '#fff',
    backgroundColor: green[500],
    '&:hover': { backgroundColor: green[600] }
  },
}))(Button);

const LogoutButton = withStyles(theme => ({
  root: {
    color: '#fff',
    backgroundColor: pink[500],
    '&:hover': { backgroundColor: pink[600] }
  },
}))(Button);

const Menu = withStyles({
  paper: { marginTop: 40, padding: 0 }
})(props => (
  <MenuMUI
    anchorOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
    {...props}
  />
));

const ProfileMenu = props => {
	const [ branchId, setBranchId ] = useState(sessionBranchId);

	const classes = useStyles();

	const handleLogout = () => {
		authServices.logout();
	}

	const handleChange = e => {
		const newBranchId = e.target.value;
		const branchData = branchesSelectField.find(tmp => parseInt(tmp.value) === parseInt(newBranchId));

		recordsServices.update(`branches/${newBranchId}/session`)
			.then(data => {
				const session = JSON.parse(localStorage.getItem('session'));  
		    window.localStorage.setItem('session', JSON.stringify({ ...session, branchId: newBranchId, branchName: branchData.label }));
		    setBranchId(newBranchId);
				props.showAlert({ variant: 'success' });
				props.getDataFromServer();
			})
			.catch(e => console.log(e.message));

		
	}

	return (
		<Menu
			id="profile-menu"
			anchorEl={ props.anchorEl }
			open={Boolean(props.anchorEl)}
			onClose={ props.onClose }
		>
			<List className={ classes.userInfo }>
				<ListItem alignItems="flex-start">
					<div className={ classes.imgContainer } >
						<Avatar
							alt="Profile"
							src={ props.img }
							className={ classes.image }
							style={{ width: "100%"}}
						>
						</Avatar>
						<div className={ classes.middle }>
							Cambiar
						</div>
					</div>
					<ListItemText
						primary={
							<Typography
								component="span"
								color="textPrimary"
							>
								<strong>{ fullName }</strong>
							</Typography>
						}
						secondary={
							<Fragment>
								<Typography
									component="span"
									color="textPrimary"
								>
								</Typography>
								<Link href="#">
									Políticas de Privacidad
								</Link>
								<AccountButton component={RouterLink} to="/MyAccount" size="small" fullWidth>
									Mi Cuenta
								</AccountButton>
							</Fragment>
						}
					/>
				</ListItem>
				<Divider />
				<ListItem alignItems="flex-start">
					{
						master && (
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<SelectField 
										noFormat
										magin='dense'
										variant='outlined'
										size='small'
			              name='currentBranch'
			              label='Sucursal'
			              value={branchId}
			              onChange={handleChange}
			              options={branchesSelectField}
			            />
								</Grid>
							</Grid>
						)
					}
				</ListItem>
				<ListItem alignItems="flex-start">
					<Grid container spacing={2}>
						<Grid item xs={6} >
							<PreferencesButton component={RouterLink} to="/Preferences" size="small" fullWidth>
								Preferencias
							</PreferencesButton>
						</Grid>
						<Grid item xs={6} >
							<LogoutButton size="small" fullWidth onClick={ handleLogout}>
								Cerrar Sesión
							</LogoutButton>
						</Grid>
					</Grid>
				</ListItem>
			</List>
		</Menu>
	)
}

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(alertActions.show(data)),
  getDataFromServer: () => dispatch(getDataFromServer())
});

export default connect(null, mapDispatchToProps)(ProfileMenu);