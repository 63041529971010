import React from 'react';
import { connect } from 'react-redux';

import ConfirmDialog from './../ConfirmDialog';
import routes from './../Module/routes';

import { closeDeleteDialog, getDataFromServer, alertActions } from './../../_actions';
import { recordsServices } from './../../_services';

const ERROR_MESSAGE = "Ha ocurrido un error, por favor inténtalo de nuevo más tarde.";

const Component = props => {

	const handleCloseDelete = () => { props.closeDialog(); }
	const handleDelete = data => {
		handleCloseDelete();
		recordsServices._delete(routes(props.route, 'DELETE', { ...data, ...props.params }))
			.then(() => {
				props.showAlert({ variant: 'success' })
				props.getDataFromServer()
			})
			.catch(e => props.showAlert({ variant: 'warning', message: ERROR_MESSAGE }));
	}

	return (
		<ConfirmDialog 
      isOpen={props.openForm[0]} 
      params={props.openForm[1]} 
      handleAction={handleDelete} 
      handleClose={handleCloseDelete} 
    />
	);
}

const mapStateToProps = state => ({
	openForm: state.deleteDialog
});

const mapDispatchToProps = dispatch => ({
	closeDialog: () => dispatch(closeDeleteDialog()),
	getDataFromServer: () => dispatch(getDataFromServer()),
	showAlert: data => dispatch(alertActions.show(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);