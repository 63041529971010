import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import DeleteIcon from '@material-ui/icons/Delete';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormDialog from './../../_presentationals/FormDialog';
import TextField from './../../_presentationals/Form/TextField';
import IconButton from './../../_presentationals/Form/IconButton';
import moneyFormat from '../../_helpers/moneyFormat';

import { alertActions, getDataFromServer } from './../../_actions';

import { recordsServices } from './../../_services';
import { errorFormat } from './../../_helpers';
import { format } from "date-fns";

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: { padding: theme.spacing(0.4) }
}));

const ERROR_MESSAGE = 'Ha ocurrido un error, por favor inténtalo de nuevo más tarde.';

const defaultValues = {
  payment: null
};

const Component = props => {

	const [values,setValues] = useState(defaultValues);
  const [errors,setErrors] = useState({});
  const [rows,setRows] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    props.values.id && setRows(props.values.Payments);
  }, [props.values]);

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }
  
  const handleAction = () => { 
    const url = `expenses/${props.values.id}/payments`;
    
    recordsServices.create(url, { ...values })
      .then(data => {
        if(data.errorCode) { throw new Error(JSON.stringify(data.errorMessage)); }
        props.showAlert({ variant: 'success' });
        props.getDataFromServer();
        handleClose();
      })
      .catch(e => { 
        const tmpErrors = errorFormat(e.message);
        setErrors(tmpErrors);
        props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
  }
  
  const handleDeletePayment = (e,{id}) => { 
    const url = `expenses/${props.values.id}/payments/${id}`;
    
    recordsServices._delete(url)
      .then(data => {
        if(data.errorCode) { throw new Error(data.errorMessage); }
        props.showAlert({ variant: 'success' });
        props.getDataFromServer();
        handleClose();
      })
      .catch(e => { 
        const tmpErrors = errorFormat(e.message);
        setErrors(tmpErrors);
        props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
  }

  const handleClose = () => { 
    setValues(defaultValues); 
    setErrors({});
    setRows([]);
    props.handleClose();
  }

	return (
		<FormDialog 
      title='Pagos'
      width='sm'
      isOpen={props.isOpen || false}
      handleClose={handleClose}
      handleAction={handleAction}
      hideAction={props.report}
    >
      {!props.report && 
        <Grid container spacing={1}>
          <Grid item lg={12} xs={12}>
            <TextField
              name='payment'
              label='Pago'
              value={values.folio}
              onChange={handleChange}
              error={errors.payment}
              size='small'
              noFormat
            />
          </Grid>
        </Grid>
      }
      <br/>
      <Grid container item xs={12} spacing={1}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>Pago</TableCell>
              {!props.report && <TableCell/> }
            </TableRow>
          </TableHead>
          <TableBody>
            { 
              rows.map(el => {
                return (
                <TableRow hover key={el.id}>
                  <TableCell>{format(new Date(el.createdAt), 'yyyy/MM/dd')}</TableCell>
                  <TableCell>{moneyFormat(el.payment)}</TableCell>
                  {!props.report && 
                    <TableCell align='right'>
                      <IconButton
                        label='Eliminar'
                        action={ handleDeletePayment } 
                        params={ { id: el.id } }
                        className={ classes.actionButton }
                        icon={<DeleteIcon fontSize='small' />}
                      />
                    </TableCell>
                  }
                </TableRow>)
              })
            }
          </TableBody>
        </Table>
      </Grid>

    </FormDialog>
   )
}

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(alertActions.show(data)),
  getDataFromServer: () => dispatch(getDataFromServer())
});

export default connect(null, mapDispatchToProps)(Component);