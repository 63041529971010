import React, { useState } from 'react';
import { connect } from 'react-redux';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormDialog from './../../_presentationals/FormDialog';
import TextField from './../../_presentationals/Form/TextField';
import AutocompleteField from './../../_presentationals/Form/AutocompleteField';
import IconButton from './../../_presentationals/Form/IconButton';

import { alertActions, getDataFromServer } from './../../_actions';

import { recordsServices } from './../../_services';
import { errorFormat } from './../../_helpers';

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: { padding: theme.spacing(0.4) }
}));

const ERROR_MESSAGE = 'Ha ocurrido un error, por favor inténtalo de nuevo más tarde.';

const defaultValues = {
  quantity: 1,
  product: { value: '', label: '' },
  description: '',
  unit: ''
};

const Component = props => {

	const [ values, setValues ] = useState(defaultValues);
  const [ errors, setErrors ] = useState({});
  const [ rows, setRows ] = useState([]);

  const classes = useStyles();

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }
  
  const handleAction = () => { 
    const url = `physicalInventories`;
    
    recordsServices.create(url, { Products: rows, description: values.description })
      .then(data => {
        if(data.errorCode) { throw new Error(JSON.stringify(data.errorMessage)); }
        props.showAlert({ variant: 'success' });
        props.getDataFromServer();
        handleClose();
      })
      .catch(e => { 
        const tmpErrors = errorFormat(e.message);
        setErrors(tmpErrors);
        props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
  }

  const handleClose = () => { 
    setValues(defaultValues); 
    setErrors({});
    setRows([]);
    setValues(defaultValues);
    props.handleClose();
  }

  const handleAdd = e => {
    try {
      const quantity = parseFloat(values.quantity);
      const productId = values.product.value;
      const label = values.product.label;
      const unit = values.product.unit;
      
      if(isNaN(quantity) || quantity < 0) {
        throw new Error(JSON.stringify([{name: 'quantity', message: ''}]));
      }

      if(productId === '') {
        throw new Error(JSON.stringify([{name: 'product', message: ''}]));
      }

      const coincidences = rows.find(el => el.productId === productId);

      if(coincidences) {
        throw new Error(JSON.stringify([{name: 'product', message: 'Este ingrediente ya fue asignado.'}]));
      }

      setRows(rows => [ ...rows, { unit, quantity, productId, label } ]);
      setErrors({});
      setValues({ ...values, quantity: 1, product: { value: '', label: '' } });

    } catch(e) {
      const tmpErrors = errorFormat(e.message);
      setErrors(tmpErrors);
      props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
    }
  }

  const handleDelete = (e, {i}) => {
    const tmp = rows.map((el, index) => index !== i && el).filter(el => el);
    setRows(tmp);
  }

	return (
		<FormDialog
      title='Productos'
      width='sm'
      isOpen={props.isOpen || false}
      handleClose={handleClose}
      handleAction={handleAction}
    >
      <Grid container spacing={1}>
        <Grid item lg={12} xs={12}>
          <TextField
            name='description'
            label='Descripción del Inventario'
            value={values.description}
            onChange={handleChange}
            size='small'
            noFormat
          />
        </Grid>
      </Grid>
      <br/>
    	<Grid container spacing={1}>
        <Grid item lg={6} xs={12}>
          <AutocompleteField
            name='product'
            label='Producto'
            variant='outlined'
            value={values.product}
            onChange={handleAutocompleteChange}
            options={props.products}
            error={errors.productId}
            size='small'
            noFormat
          />
        </Grid>
        <Grid item lg={2} xs={12}>
          <TextField
            name='quantity'
            label='Cant.'
            variant='outlined'
            value={values.quantity}
            onChange={handleChange}
            error={errors.quantity}
            size='small'
            noFormat
          />
        </Grid>
        <Grid item lg={3} xs={12}>
          <TextField
            name='unit'
            label='Unidad.'
            variant='outlined'
            value={values.product.unit || ''}
            onChange={handleChange}
            error={errors.quantity}
            disabled
            size='small'
            noFormat
          />
        </Grid>
        <Grid item lg={1} xs={12} align='right'>
          <Fab color='secondary' size='small' onClick={ handleAdd } aria-label='Agregar Producto'>
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Producto.</TableCell>
              <TableCell>Unidad.</TableCell>
              <TableCell>Cant.</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            { 
              rows.map((el, i) => {
                return (
                <TableRow hover key={el.productId}>
                  <TableCell>{el.label}</TableCell>
                  <TableCell>{el.unit}</TableCell>
                  <TableCell>{el.quantity}</TableCell>
                  <TableCell align='right'>
                    <IconButton
                      label='Eliminar'
                      action={ handleDelete } 
                      params={ { i } }
                      className={ classes.actionButton }
                      icon={<DeleteIcon fontSize='small' />}
                    />
                  </TableCell>
                </TableRow>)
              })
            }
          </TableBody>
        </Table>
      </Grid>
    </FormDialog>
   )
}



const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(alertActions.show(data)),
  getDataFromServer: () => dispatch(getDataFromServer())
});

export default connect(null, mapDispatchToProps)(Component);