import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import RoomIcon from '@material-ui/icons/RoomOutlined';

import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';
import { statesSelectField } from './../../_helpers';

import Module from './../../_presentationals/Module';

const Rows = [
	{ label: 'Nombre', key: 'name', sort: 'text', filter: true }, 
	{ label: 'Télefono', key: 'phone', sort: 'text', filter: true }, 
	{ label: 'Dirección', key: 'address' }
];

const tmpCountry = [{ value: 1, label: 'México' }];
const tmpStates = statesSelectField(1);

const Component = ({ getData, ...props }) => {
	const [ values, setValues ] = useState([]);
	
	useEffect(() => {
		getData && recordsServices.get('suppliers')
			.then(data => {
				const tmp = data.map(el => ({
          id: el.id, name: el.name, phone: el.phone,
          address: `${el.addressLine1 || ''} ${el.addressLine2 || ''} ${el.city || ''} ${el.zipCode || ''} ${el.State ? el.State.name : ''}`
        }));
				setValues(tmp);
        props.getDataFromServer();
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Module 
			route="suppliers"
      title="Proveedores"
      singular="Proveedor"
      Rows={Rows}
      records={values}
      fields={[
      	{ name: 'name', label: 'Nombre' },
        { icon: <PhoneIcon />, name: 'phone', label: 'Télefono' },
        { icon: <RoomIcon />, name: 'businessName', label: 'Razón Social' },
        { name: 'addressLine1', label: 'Calle y Número' },
        { name: 'addressLine2', label: 'Colonia' },
        { name: 'city', label: 'Ciudad' },
        { name: 'zipCode', label: 'CP' },
        { name: 'email', label: 'Correo' },
        { name: 'countryId', label: 'País', field: 'select', options: tmpCountry },
        { name: 'stateId', label: 'Estado', field: 'select', options: tmpStates }
      ]}
    />
	);
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);