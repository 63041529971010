import { actionDialogConstants } from '../_constants';

const defaultActionValues = [ false, {} ];
const defaultFilterValues = [ false, {} ];

const actionDialog = (state = defaultActionValues, action) => {
  switch (action.type) {
    case actionDialogConstants.OPEN_FORM_DIALOG:
      return [ true, action.payload ];
    case actionDialogConstants.CLOSE_FORM_DIALOG:
      return defaultActionValues;
    default:
      return state;
  }
};

const filterDialog = (state = defaultFilterValues, action) => {
  switch (action.type) {
    case actionDialogConstants.OPEN_FILTER_DIALOG:
      return [ true, action.payload ];
    case actionDialogConstants.CLOSE_FILTER_DIALOG:
      return defaultFilterValues;
    default:
      return state;
  }
};

export { actionDialog, filterDialog };