import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormDialog from './../../_presentationals/FormDialog';
import TextField from './../../_presentationals/Form/TextField';
import IconButton from './../../_presentationals/Form/IconButton';

import { alertActions, getDataFromServer } from './../../_actions';

import { recordsServices } from './../../_services';
import { errorFormat } from './../../_helpers';

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: { padding: theme.spacing(0.4) }
}));

const ERROR_MESSAGE = 'Ha ocurrido un error, por favor inténtalo de nuevo más tarde.';

const defaultValues = { name: '', unit: 1, Units: [] };

const Component = props => {

	const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [rows, setRows] = useState([]);

  const classes = useStyles();

	useEffect(() => {
		props.values.id && setRows(props.values.Units);
	}, [props.values]);

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAction = () => { 
    const url = `products/${props.values.id}/units`;
    
    recordsServices.update(url, { Units: rows })
      .then(data => {
        if(data.errorCode) { throw new Error(data.errorMessage); }
        props.showAlert({ variant: 'success' });
        props.getDataFromServer();
        handleClose();
      })
      .catch(e => { 
        const tmpErrors = errorFormat(e.message);
        setErrors(tmpErrors);
        props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
  }

  const handleClose = () => { 
    setValues(defaultValues); 
    setErrors({});
    setRows([]);
    props.handleClose();
  }
  // console.log(rows);
  const handleAdd = e => {
    try {
      const name = values.name;
      const unit = parseFloat(values.unit);
      console.log(name);
      if(name === '') {
        throw new Error(JSON.stringify([{name: 'product', message: ''}]));
      }

      if(isNaN(unit) || unit < 0) {
        throw new Error(JSON.stringify([{name: 'qty', message: ''}]));
      }

      setRows(rows => [ ...rows, { name, unit } ]);
      setErrors({});
      setValues(defaultValues);

    } catch(e) {
      const tmpErrors = errorFormat(e.message);
      setErrors(tmpErrors);
      props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
    }
  }

  const handleDelete = (e, {i}) => {
    const tmp = rows.map((el, index) => index !== i && el).filter(el => el);
    setRows(tmp);
  }
  
	return (
		<FormDialog
      title='Unidades'
      width='sm'
      isOpen={props.isOpen || false}
      handleClose={handleClose}
      handleAction={handleAction}
    >
    	<Grid container spacing={1}>
        <Grid item lg={8} xs={12}>
          <TextField
            name='name'
            label='Nombre'
            variant='outlined'
            value={values.name}
            onChange={handleChange}
            error={errors.name}
            size='small'
            noFormat
          />
        </Grid>
        <Grid item lg={3} xs={12}>
          <TextField
            name='unit'
            label='Unidad'
            variant='outlined'
            value={values.unit}
            onChange={handleChange}
            error={errors.unit}
            size='small'
            noFormat
          />
        </Grid>
        <Grid item lg={1} xs={12} align='right'>
          <Fab color='secondary' size='small' onClick={ handleAdd } aria-label='Agregar Ingrediente'>
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell align='center'>Unit</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            { 
              rows.map((el, i) => {
                return (
                <TableRow hover key={el.name}>
                  <TableCell>{el.name}</TableCell>
                  <TableCell align='center'>{el.unit}</TableCell>
                  <TableCell align='right'>
                    <IconButton
                      label='Eliminar'
                      action={ handleDelete } 
                      params={ { i } }
                      className={ classes.actionButton }
                      icon={<DeleteIcon fontSize='small' />}
                    />
                  </TableCell>
                </TableRow>)
              })
            }
          </TableBody>
        </Table>
      </Grid>
    </FormDialog>
   )
}

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(alertActions.show(data)),
  getDataFromServer: () => dispatch(getDataFromServer())
});

export default connect(null, mapDispatchToProps)(Component);