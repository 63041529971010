import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';
import { queryFormat } from './../../_helpers/general';
import moneyFormat from './../../_helpers/moneyFormat';

import Module from './../../_presentationals/Module';

const Rows = [
	{ label: 'Producto', key: 'name', sort: 'text', filter: true },
	{ label: 'Cant.', key: 'qty', align: 'center' }
];

const Component = ({ getData, ...props }) => {
	const [ values, setValues ] = useState([]);
	const [ amount, setAmount ] = useState(0);
	const [ products, setProducts ] = useState([]);
	
	useEffect(() => {
		getData && recordsServices.get(`sales/ingredients`)
			.then(data => {
				const totalQty = data.reduce((totalQty = 0,el) => (parseFloat(totalQty) + parseFloat(el.qty)),0);
				setValues(data);
				setAmount(totalQty);
        props.getDataFromServer();
			})
			.catch(e => console.log(e.message));

		getData && recordsServices.get('products?type=1')
      .then(data => {
        const tmp = data.map(el => ({ value: el.id, label: el.name }));
        setProducts(tmp);
      })
      .catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps
	
	const handleFilter = val => {
		const { 
			start = format(new Date(),'yyyy/MM/dd'),
			finish = format(new Date(),'yyyy/MM/dd'),
		} = val;
		recordsServices.get(`sales/ingredients?${queryFormat({ start, finish, productId: val.productId })}`)
		.then(data => {
			const totalQty = data.reduce((totalQty = 0,el) => (parseFloat(totalQty) + parseFloat(el.qty)),0);
			setValues(data);
			setAmount(totalQty);
		})
		.catch(e => console.log(e.message));
	};

	return (
		<Module 
			route='sales/ingredients'
      title='Reporte de Rotación de Ingredientes'
      singular='Rotación de Ingredientes'
      subTitle={`Total: ${moneyFormat(amount)}`}
      Rows={Rows}
      records={values}
      noCreate={true}
      noDelete={true}
      noEdit={true}
      filter={[handleFilter,
      	[
					{ field: 'date', label: 'Inicio', name: 'start' },
					{ field: 'date', label: 'Fin', name: 'finish' },
					{ field: 'select', label: 'Producto', name: 'productId', options: products }
				]
      ]}
    />
	);
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);