import React from "react";

import TextFieldMUI  from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const TextField = ({ noFormat, icon, error, ...props }) => 
  !noFormat ?
    <Grid container spacing={0} alignItems="flex-end">
      <Grid item xs={1}>
        { icon || "" }
      </Grid>
      <Grid item xs={10}>
        <TextFieldMUI 
          name={props.name}
          label={props.label}
          variant={ props.variant || "standard"}
          fullWidth={props.fullWidth || true}
          autoFocus={props.autoFocus || false}
          onChange={props.onChange}
          value={props.value}
          error={error ? true : false}
          helperText={error && error}
          autoComplete="off"
          size="small"
          { ...props }
        />
      </Grid>
    </Grid>
  :
    <TextFieldMUI 
      name={props.name}
      label={props.label}
      variant={ props.variant || "standard"}
      fullWidth={props.fullWidth || true}
      autoFocus={props.autoFocus || false}
      onChange={props.onChange}
      value={props.value}
      error={error ? true : false}
      helperText={error && error}
      autoComplete='off'
      { ...props }
    />

export default TextField;