import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import CameraAltIcon from '@material-ui/icons/CameraAltOutlined';

import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';

import Module from './../../_presentationals/Module';

const Rows = [
	{ label: 'Nombre', key: 'name', sort: 'text', filter: true }, 
	{ label: '¿Se vende?', key: 'showLabel' },
  { label: '¿Aplica inventario?', key: 'inventoryLabel' }
];

const showOptions = [ { value: false, label: 'No' }, { value: true, label: 'Si' } ];

const Component = ({ getData, ...props }) => {
	const [ values, setValues ] = useState([]);
	
	useEffect(() => {
		getData && recordsServices.get('clasifications')
			.then(data => {
        const tmp = data.map(el => ({ ...el, showLabel: el.show ? 'Si' : 'No', inventoryLabel: el.inventory ? 'Si' : 'No' }));
				setValues(tmp);
        props.getDataFromServer();
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleImage = (e, { id }) => {
    console.log('Imagen');
  }

	return (
		<Module 
			route='clasifications'
      title='clasificaciones'
      singular='Clasificación'
      Rows={Rows}
      records={values}
      actions={[{
        icon: <CameraAltIcon />,
        label: 'Añadir Imagen',
        onClick: handleImage
      }]}
      fields={[
      	{ name: 'name', label: 'Nombre' },
      	{ name: 'show', label: '¿Se vende?', field: 'select', options: showOptions },
        { name: 'inventory', label: '¿Lleva inventario?', field: 'select', options: showOptions },
      ]}
    />
	);
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);