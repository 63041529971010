import React from "react";

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const DialogTitle = withStyles(theme => ({
  linearProgress: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis'
  },
  typography: { margin: theme.spacing(2) }
}))(props => {
  const { children, classes } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography className={ classes.typography } variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1)
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export { 
  DialogTitle,
  DialogContent,
  DialogActions
};