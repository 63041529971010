import React from 'react';
import { connect } from 'react-redux';

import dateFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO'

import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';

import IconButton from './../../Form/IconButton';
import BodyCell from './../helpers/BodyCell';
import routes from './../../Module/routes';
import useStyles from './style';

import { openActionDialog, openDeleteDialog } from './../../../_actions';
import { recordsServices } from './../../../_services';
import moneyFormat from './../../../_helpers/moneyFormat';

const Component = props => {
	
	const classes = useStyles();

	const handleOpenEdit = (e, params) => {
    recordsServices.get(routes(props.route, 'GET_BY_PK', params))
      .then(data => props.openForm(data))
      .catch(e => console.log(e.message));
  }

  const handleOpenDelete = (e, id) => { props.openDelete(id); }
  
	return (
		<TableBody>
			{ props.records && props.records.map(data => 
				<TableRow key={data.id} tabIndex={-1} hover>
					{ props.Rows.map(el => 
						<BodyCell key={el.key} align={el.align} noWrap={!el.wrap} >
							{ format(data[el.key], el.format) }
						</BodyCell>
					)}
					<BodyCell align="right" noWrap>
						{
							props.actions && props.actions.map(el => 
								<IconButton 
									key={ el.label }
				      		label={ el.label }
				      		action={ el.onClick } 
				      		params={ { id: data.id, ...el.params } }
				      		className={ classes.actionButton }
				      		icon={ el.icon }
				      	/>
							)
						}
						{
							!props.noEdit && (
								<IconButton 
				      		label="Editar" 
				      		action={ handleOpenEdit } 
				      		params={ { id: data.id, ...props.extraParams } }
				      		className={ classes.actionButton }
				      		icon={<EditIcon fontSize="small" />}
				      	/>
				      )

						}
						{
							!props.noDelete && (
				      	<IconButton 
				      		label="Eliminar" 
				      		action={ handleOpenDelete } 
				      		params={ { id: data.id, ...data } }
				      		className={ classes.actionButton }
				      		icon={<DeleteIcon fontSize="small" />}
				      	/>
				      )
						}
  				</BodyCell>
				</TableRow>
			)}
		</TableBody>
	);
}

const format = (value, type) => {
	switch(type){
		case 'date':
			return dateFormat(parseISO(value), 'dd/MM/yyyy');
		case 'dateTime':
			return dateFormat(parseISO(value), 'dd/MM/yyyy HH:mm:ss');
		case 'time':
			return dateFormat(parseISO(value), 'HH:mm:ss');
		case 'currency':
			return moneyFormat(value);
		case 'balance':
			return (parseFloat(value) === 0) ? '-' : moneyFormat(value);
		default: return value;
	}
}

const mapDispatchToProps = dispatch => ({
	openForm: data => dispatch(openActionDialog({ ...data })),
	openDelete: id => dispatch(openDeleteDialog(id))
});

export default connect(null, mapDispatchToProps)(Component);