import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// import makeStyles from '@material-ui/core/styles/makeStyles';

import FormDialog from './../../_presentationals/FormDialog';
import TextField from './../../_presentationals/Form/TextField';

import { alertActions, getDataFromServer } from './../../_actions';

// const useStyles = makeStyles((theme: Theme) => ({
//   actionButton: { padding: theme.spacing(0.4) }
// }));

const defaultValues = {
  size: 0,
};

const Component = props => {

	const [ values, setValues ] = useState(defaultValues);
  const [ errors, setErrors ] = useState({});
  
  // const classes = useStyles();

  useEffect(() => {
    if(props.params.el && props.params.el.size){
      setValues(values => ({ ...values, size: props.params.el.size }));
    }
  }, [props.params]);

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleClose = () => {
    props.handleClose();
  }

  const handleAction = () => {
    try {
      if(isNaN(values.size) || values.size === ''){
        throw new Error("Escribe una porción para el ingrediente");
      }
      props.handleAction(props.params, values);
      setValues(defaultValues); 
    }catch (e){
      setErrors({ size: e.message });
    }
    
  }
  
	return (
		<FormDialog
      title='Editar Ingrediente'
      isOpen={props.isOpen || false}
      handleClose={handleClose}
      handleAction={handleAction}
    >
      <TextField
        name='size'
        label='Porción'
        value={values.size}
        onChange={handleChange}
        error={errors.size}
      />
    </FormDialog>
  )
}

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(alertActions.show(data)),
  getDataFromServer: () => dispatch(getDataFromServer())
});

export default connect(null, mapDispatchToProps)(Component);