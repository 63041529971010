import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import MapIcon from '@material-ui/icons/MapOutlined';

import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';

import Module from './../../_presentationals/Module';
import History from '../../_helpers/history';

const Rows = [
	{ label: 'País', key: 'name', sort: 'text', filter: true }, 
	{ label: 'Código', key: 'countryCode' }
];

const Component = ({ getData, ...props }) => {
	const [ countries, setCountries ] = useState([]);
	
	useEffect(() => {
		getData && recordsServices.get('locations/countries')
			.then(data => {
				setCountries(data);
        props.getDataFromServer();
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleStates = (e, { id }) => {
    History.replace(`/country/${id}/state`);
    props.getDataFromServer();
  }

  

	return (
		<Module 
			route='countries'
      title='países'
      singular='País'
      Rows={Rows}
      records={countries}
      actions={[{
        icon: <MapIcon />,
        label: 'Estados',
        onClick: handleStates
      }]}
      fields={[
      	{ name: 'name', label: 'Nombre' },
      	{ name: 'countryCode', label: 'Código' }
      ]}
    />
	);
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);