import makeStyles from '@material-ui/core/styles/makeStyles';
import green from '@material-ui/core/colors/green';
import { Theme } from "@material-ui/core/styles";
import background from "../../_helpers/files/back.jpg";

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundImage: `url(${background})`,
    // background: "#fec001",
    minHeight: "100vh",
    margin: 0,
    padding: theme.spacing(1),
    fontFamily: "'Heebo', sans-serif"
  },
  body: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  footer: {
    color: "#fff"
  },
  logo: {
    width: 180,
    height: 180,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5)
    }
  },
  registerButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    backgroundColor: green[500],
    color: "#fff",
    '&:hover': {
      backgroundColor: green[700],
    },
    textTransform: "capitalize",
    fontWeight: 700,
    letterSpacing: -1
  },
  typographyTitle1: {
    color: "#313131", 
    fontWeight: 300
  },
  typographySubtitle1: {
    color: "#595959", 
    lineHeight: 1.5
  },

}));

export default useStyles;