import React from 'react';
import { Router } from "react-router-dom";

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import history from './_helpers/history';
import Navigation from "./_containers/Navigation";

import Routes from "./Routes";
import 'typeface-roboto';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [ 'Roboto', 'Arial', 'sans-serif'].join(',')
  },
  palette: {  
    background: { default: '#fff' } 
  },
  overrides: {
    MuiFab: {
      root: { boxShadow: 'none' }
    }
  }
});

const token = localStorage.getItem('token');

const App = () => 
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router history={history}>
      { token ? <Navigation /> : <Routes /> }
    </Router>
  </MuiThemeProvider>

export default App;
