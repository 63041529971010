import { combineReducers } from 'redux';

import { alert } from './alert.reducer';
// import { auth } from './auth.reducer';
// import { field } from './field.reducer';
import { actionDialog, filterDialog } from './actionDialog.reducer';
import { deleteDialog } from './deleteDialog.reducer';
// import { userNoAuth, user } from './user.reducer';
// import { profile } from './profile.reducer';
// import { progress } from './progress.reducer';
// import { records } from './records.reducer';
// import { redirect } from './redirect.reducer';
import { searchField } from './searchField.reducer';
import { getData } from './getData.reducer';
import { xlsDoc } from './xlsDoc.reducer';
import { pdfDoc } from './pdfDoc.reducer';

const rootReducer = combineReducers ({
	alert,
  actionDialog,
  filterDialog,
  deleteDialog,
  searchField,
  getData,
  xlsDoc,
  pdfDoc,
});
export default rootReducer;


// alert,
//   auth,
//   field,
//   user,
//   userNoAuth,
//   profile,
//   progress,
//   records,
//   redirect,
//   