import React, { useState } from "react";

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button  from '@material-ui/core/Button';

import { authServices, recordsServices } from './../../_services';
import TextField from './../../_presentationals/Form/TextField';

import logo from './../../_helpers/files/logo.png';
import useStyles from './style';

const Login = () => {
  
  const [ values, setValues ] = useState({});
  const [ errors, setErrors ] = useState({});

  const classes = useStyles();

  const handleLogin = () => {
    setErrors({});
    authServices.login(values).then(async res => {
      const countriesRequest = await recordsServices.get('locations/countries');
      const countries = await Promise.all(countriesRequest.map(async (el, index) => {
         const states = await recordsServices.get(`locations/countries/${el.id}/states`);
         return { ...el, states };
      }));
      localStorage.setItem('countries', JSON.stringify(countries)); 
      const branches = await recordsServices.get('branches');
      localStorage.setItem('branches', JSON.stringify(branches)); 
      window.location.reload(true);
    }).catch(e => {
      console.log(e.message);
      JSON.parse(e.message).forEach(({ name, message }) => {
        setErrors(errors => ({ ...errors, [name]: message }));
      })
    });
  }

  const handleForgotPassword = e => {
    e.preventDefault();
    console.log("Olvidé mi contraseña");
  }

  const handleKeyPress = e => (e.keyCode === 13) && handleLogin();

  const handleChange = e => {
    const { name, value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  }

  return (
    <div className={ classes.background }>
      <Grid container item xs={12} justify="center">
        <img src={logo} className={classes.logo} alt="logo" />
      </Grid>
      <Grid container spacing={0} justify="center">
        <Grid item md={5} xs={12}>
          <Card className={classes.body}>
            <CardContent>
              <Typography variant="h4" align="center" className={classes.typographyTitle1}>
                Iniciar Sesión
              </Typography>
              <Typography variant="subtitle1" align="center" className={classes.typographySubtitle1}>
                Una herramienta inteligente, veloz y fácil de usar.
              </Typography>
              <br/>
              <TextField 
                name="username"
                label="Usuario"
                variant="outlined"
                autoFocus={true}
                onChange={handleChange}
                onKeyDown={handleKeyPress}
                value={values.username || ""}
                error={errors.username && errors.username}
                noFormat
              />
              <TextField 
                name="password"
                label="Contraseña"
                variant="outlined"
                margin="normal"
                type="password"
                onChange={handleChange}
                onKeyDown={handleKeyPress}
                value={values.password || ""}
                error={errors.password}
                noFormat
              />
              <Button variant="contained" onClick={handleLogin} color="primary" className={classes.registerButton} size="large" fullWidth>
                Iniciar Sesión
              </Button>
            </CardContent>
          </Card>
          <Typography component="h2" variant="body1" className={classes.footer} align="center" gutterBottom>
            ¿Olvidaste tu contraseña? <Link href="#" onClick={handleForgotPassword} className={classes.footer} underline="always">Recuperar Ahora</Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;