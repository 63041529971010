import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormDialog from './../../_presentationals/FormDialog';
import TextField from './../../_presentationals/Form/TextField';
import SelectField from './../../_presentationals/Form/SelectField';
import AutocompleteField from './../../_presentationals/Form/AutocompleteField';
import IconButton from './../../_presentationals/Form/IconButton';

import { alertActions, getDataFromServer } from './../../_actions';

import { recordsServices } from './../../_services';
import { errorFormat } from './../../_helpers';

import Edit from './edit.ingredient.form';

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: { padding: theme.spacing(0.4) }
}));

const ERROR_MESSAGE = 'Ha ocurrido un error, por favor inténtalo de nuevo más tarde.';

const defaultValues = {
  qty: 1,
  product: { value: '', label: '' },
  size: 0,
  type: 0,
  show: 1,
  isCostIncluded: 1,
  isRequired: 0,
  isOptional: 0
};

const typeOptions = [{ value: 0, label: 'No' }, { value: 1, label: 'Si' }];

const Component = props => {

	const [ values, setValues ] = useState(defaultValues);
  const [ errors, setErrors ] = useState({});
  const [ rows, setRows ] = useState([]);
  const [ isOpen, setIsOpen ] = useState([false, {}]);

  const classes = useStyles();

	useEffect(() => {
		props.values.id && setRows(props.values.Ingredients);
	}, [props.values]);

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAction = () => { 
    const url = `products/${props.values.id}/ingredients`;
    
    recordsServices.update(url, { Ingredients: rows })
      .then(data => {
        if(data.errorCode) { throw new Error(data.errorMessage); }
        props.showAlert({ variant: 'success' });
        props.getDataFromServer();
        handleClose();
      })
      .catch(e => { 
        const tmpErrors = errorFormat(e.message);
        setErrors(tmpErrors);
        props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
  }

  const handleClose = () => { 
    setValues(defaultValues); 
    setErrors({});
    setRows([]);
    setValues(defaultValues);
    props.handleClose();
  }

  const handleAdd = e => {
    try {
      const qty = parseFloat(values.qty);
      const size = parseFloat(values.size);
      const productId = values.product.value;
      const label = values.product.label;
      const type = parseInt(values.type);
      const show = parseInt(values.show);
      const isRequired = parseInt(values.isRequired);
      const isCostIncluded = parseInt(values.isCostIncluded);
      const isOptional = parseInt(values.isOptional);

      if(isNaN(qty) || qty < 0) {
        throw new Error(JSON.stringify([{name: 'qty', message: ''}]));
      }

      if(productId === '') {
        throw new Error(JSON.stringify([{name: 'product', message: ''}]));
      }

      if(isNaN(size) || size < 0) {
        throw new Error(JSON.stringify([{name: 'size', message: ''}]));
      }

      if(![0, 1].includes(type)) {
        throw new Error(JSON.stringify([{name: 'type', message: ''}]));
      }
      
      if(![0, 1].includes(show)) {
        throw new Error(JSON.stringify([{name: 'show', message: ''}]));
      }

      if(![0, 1].includes(isCostIncluded)) {
        throw new Error(JSON.stringify([{name: 'isCostIncluded', message: ''}]));
      }

      if(![0, 1].includes(isRequired)) {
        throw new Error(JSON.stringify([{name: 'isRequired', message: ''}]));
      }

      if(![0, 1].includes(isOptional)) {
        throw new Error(JSON.stringify([{name: 'isOptional', message: ''}]));
      }

      const coincidences = rows.find(el => el.productId === productId);

      if(coincidences) {
        throw new Error(JSON.stringify([{name: 'product', message: 'Este ingrediente ya fue asignado.'}]));
      }

      setRows(rows => [ ...rows, { qty, productId, label, type, show, isCostIncluded, isRequired, isOptional, size } ]);
      setErrors({});
      setValues(defaultValues);

    } catch(e) {
      const tmpErrors = errorFormat(e.message);
      setErrors(tmpErrors);
      props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
    }
  }

  const handleDelete = (e, {i}) => {
    const tmp = rows.map((el, index) => index !== i && el).filter(el => el);
    setRows(tmp);
  }

  const handleConfig = (e, {i, el}) => {
    setIsOpen([true, {i, el}]);
  }

  const handleCloseConfig = () => {
    setIsOpen([false, {}]);
  }

  const handleActionConfig = (params, values) => {
    const tmp = rows.map((el,i) => parseInt(i) === parseInt(params.i)
      ? { ...el, ...values }
      : el
    );
    setRows(tmp);
    setErrors({});
    setValues(defaultValues);
    setIsOpen([false, {}]);
  }
  
	return (
    <Fragment>
  		<FormDialog
        title='Ingredientes'
        fullScreen
        isOpen={props.isOpen || false}
        handleClose={handleClose}
        handleAction={handleAction}
      >
      	<Grid container spacing={1}>
          <Grid item lg={1} xs={12}>
            <TextField
              name='qty'
              label='Cant.'
              variant='outlined'
              value={values.qty}
              onChange={handleChange}
              error={errors.qty}
              size='small'
              noFormat
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <AutocompleteField
              name='product'
              label='Ingrediente'
              variant='outlined'
              value={values.product}
              onChange={handleAutocompleteChange}
              options={props.ingredients}
              error={errors.product}
              size='small'
              noFormat
            />
          </Grid>
          <Grid item lg={1} xs={12}>
            <TextField
              name='size'
              label='Porción'
              variant='outlined'
              value={values.size}
              onChange={handleChange}
              error={errors.size}
              size='small'
              noFormat
            />
          </Grid>
          <Grid item lg={1} xs={12}>
            <SelectField
              name='type'
              label='¿Base?'
              variant='outlined'
              value={values.type}
              onChange={handleChange}
              options={typeOptions}
              error={errors.type}
              size='small'
              noFormat
            />
          </Grid>
          <Grid item lg={1} xs={12}>
            <SelectField
              name='show'
              label='Para Venta'
              variant='outlined'
              value={values.show}
              onChange={handleChange}
              options={typeOptions}
              error={errors.show}
              size='small'
              noFormat
            />
          </Grid>
          <Grid item lg={1} xs={12}>
            <SelectField
              name='isCostIncluded'
              label='Cargar Costo'
              variant='outlined'
              value={values.isCostIncluded}
              onChange={handleChange}
              options={typeOptions}
              error={errors.isCostIncluded}
              size='small'
              noFormat
            />
          </Grid>
          <Grid item lg={1} xs={12}>
            <SelectField
              name='isRequired'
              label='Obligatorio'
              variant='outlined'
              value={values.isRequired}
              onChange={handleChange}
              options={typeOptions}
              error={errors.isRequired}
              size='small'
              noFormat
            />
          </Grid>
          <Grid item lg={1} xs={12}>
            <SelectField
              name='isOptional'
              label='Opcional'
              variant='outlined'
              value={values.isOptional}
              onChange={handleChange}
              options={typeOptions}
              error={errors.isOptional}
              size='small'
              noFormat
            />
          </Grid>
          <Grid item lg={1} xs={12}>
          </Grid>
          <Grid item lg={1} xs={12} align='right'>
            <Fab color='secondary' size='small' onClick={ handleAdd } aria-label='Agregar Ingrediente'>
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Cant.</TableCell>
                <TableCell>Ingrediente</TableCell>
                <TableCell align='center'>Porción</TableCell>
                <TableCell align='center'>¿Base?</TableCell>
                <TableCell align='center'>¿Mostrar al vender?</TableCell>
                <TableCell align='center'>¿Influye en costo?</TableCell>
                <TableCell align='center'>¿Es obligatorio?</TableCell>
                <TableCell align='center'>¿Es Opcional?</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              { 
                rows.map((el, i) => {
                  return (
                  <TableRow hover key={el.productId}>
                    <TableCell>{el.qty}</TableCell>
                    <TableCell>{el.label}</TableCell>
                    <TableCell align='center'>{el.size ? el.size : '-'}</TableCell>
                    <TableCell align='center'>{typeOptions[el.type].label}</TableCell>
                    <TableCell align='center'>{typeOptions[el.show].label}</TableCell>
                    <TableCell align='center'>{typeOptions[el.isCostIncluded].label}</TableCell>
                    <TableCell align='center'>{typeOptions[el.isRequired] ? typeOptions[el.isRequired].label : '-'}</TableCell>
                    <TableCell align='center'>{typeOptions[el.isOptional] ? typeOptions[el.isOptional].label : '-'}</TableCell>
                    <TableCell align='right'>
                      <IconButton
                        label='Editar'
                        action={ handleConfig } 
                        params={ { i, el } }
                        className={ classes.actionButton }
                        icon={<EditIcon fontSize='small' />}
                      />
                      <IconButton
                        label='Eliminar'
                        action={ handleDelete } 
                        params={ { i } }
                        className={ classes.actionButton }
                        icon={<DeleteIcon fontSize='small' />}
                      />
                    </TableCell>
                  </TableRow>)
                })
              }
            </TableBody>
          </Table>
        </Grid>
      </FormDialog>
      <Edit isOpen={isOpen[0]} handleAction={handleActionConfig} params={isOpen[1]} handleClose={handleCloseConfig} />
    </Fragment>
   )
}

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(alertActions.show(data)),
  getDataFromServer: () => dispatch(getDataFromServer())
});

export default connect(null, mapDispatchToProps)(Component);