import React from 'react';

import TableSortLabel from '@material-ui/core/TableSortLabel';

const Component = props => {

	const handleClick = () => { props.onClick(props.id); }

	return (
		<TableSortLabel active direction={props.direction || 'asc'} onClick={handleClick}>
			{ props.label }
		</TableSortLabel>
	)
}

export default Component;