import React, { useState } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';
import { queryFormat } from './../../_helpers/general';

import Module from './../../_presentationals/Module';

const Rows = [
	{ label: 'Fecha', key: 'clientCreatedAt', sort: 'date', format: 'dateTime' },
	{ label: 'Folio de Venta', key: 'folio', sort: 'text' },
	{ label: 'Forma de Pago', key: 'paymentMethod', sort: 'text' },
	{ label: 'Importe', key: 'amount', align: 'right', format: 'currency' },
	{ label: 'Sucursal', key: 'branch', sort: 'text' }
];

const paymentMethodOptions = [
	{ label: 'Todos', value: -1 },
	{ label: 'Efectivo', value: -2 },
	{ label: 'Tarjeta (Mercado Pago)', value: 3 },
	{ label: 'Ding Efectivo', value: 6 },
	{ label: 'Ding Vale', value: 7 },
];

const Component = ({ getData, ...props }) => {
	const [ values, setValues ] = useState([]);
	
	const handleFilter = ({ start = format(new Date(),'yyyy/MM/dd'), finish = format(new Date(),'yyyy/MM/dd'), paymentMethodId }) => {
		recordsServices.get(`transactions?${queryFormat({ start, finish, paymentMethodId })}`)
		.then(data => {
			setValues(data);
		})
		.catch(e => console.log(e.message));
	};

	return (
		<Module 
			route='transactions'
      title='Reporte de Transacciones'
      singular='Transacciones'
      Rows={Rows}
      records={values}
      noCreate={true}
      noDelete={true}
      noEdit={true}
      filter={[handleFilter,
      	[
					{ field: 'date', label: 'Inicio', name: 'start' },
					{ field: 'date', label: 'Fin', name: 'finish' },
					{ field: 'select', label: 'Método de Pago', name: 'paymentMethodId', options: paymentMethodOptions },
				]
      ]}
    />
	);
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);