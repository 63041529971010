import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';

import Module from './../../_presentationals/Module';

const Rows = [
	{ label: 'Estado', key: 'name', sort: 'text', filter: true }, 
];

const Component = ({ getData, ...props }) => {
	const [ values, setValues ] = useState([]);
	const { countryId } = useParams();
	
	useEffect(() => {
		getData && recordsServices.get(`locations/countries/${countryId}/states`)
			.then(data => {
				setValues(data);
        props.getDataFromServer();
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Module 
			route='states'
      title='Estados'
      singular='Estado'
      Rows={Rows}
      records={values}
      redirect='/country'
      extraParams={{ countryId }}
      fields={[{ name: 'name', label: 'Nombre' }]}
    />
	);
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);