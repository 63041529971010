const routes = (route, method, params) => {
	switch(route){
		case 'countries':
			if(['GET_BY_PK', 'DELETE', 'UPDATE'].includes(method)) return `locations/countries/${params.id}`;
			return 'locations/countries/';
		case 'states':
			if(['GET_BY_PK', 'DELETE', 'UPDATE'].includes(method)) return `locations/countries/${params.countryId}/states/${params.id}`;
			return `locations/countries/${params.countryId}/states`;
		default: 
			if(['GET_BY_PK', 'DELETE', 'UPDATE'].includes(method)) return `${route}/${params.id}`;
			return route;
	}
}

export default routes;