import React from 'react';
import { connect } from 'react-redux';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import PrintIcon from '@material-ui/icons/PrintOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';

import { createXLS, createPDF } from './../../../_actions';

const Component = props => {
  const createXLS = () => {
    props.createXLS(true);
  };
  const createPDF = () => {
    props.createPDF(true);
  };
  return(
    <Menu
      id="module-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.onClose}
    >
      <MenuItem onClick={createPDF}>
        <ListItemIcon>
          <PrintIcon />
        </ListItemIcon>
        <ListItemText primary="Descargar | PDF" />
      </MenuItem>
      <MenuItem onClick={createXLS}>
        <ListItemIcon>
          <CloudDownloadIcon />
        </ListItemIcon>
        <ListItemText primary="Descargar | XLS" />
      </MenuItem>
      { props.redirect && <Divider /> }
      {
        props.redirect && 
          <MenuItem onClick={props.redirect}>
            <ListItemIcon>
              <KeyboardReturnIcon />
            </ListItemIcon>
            <ListItemText primary="Regresar" />
          </MenuItem>
      }
    </Menu>
  );
}

const mapDispatchToProps = dispatch => ({
  createXLS: (value) => dispatch(createXLS(value)),
  createPDF: (value) => dispatch(createPDF(value))
});

export default connect(null, mapDispatchToProps)(Component);