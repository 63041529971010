import React, { useState } from 'react';

import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';

import useStyles from "./style";
import MenuItems from "./drawer";
import ProfileMenu from "./profileMenu";

import Logo from '../../_helpers/files/logo.png';
import Routes from "./../../Routes";

const profile = "https://iupac.org/100/wp-content/uploads/sites/2/chemist/3237/Koala.jpg";

const Component = props => {

  const session = JSON.parse(localStorage.getItem('session'));
  const branchName = session ? session.branchName : '';
  
	const classes = useStyles();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = useState(false);
	const [profileOpen, setProfileOpen] = useState(null);

	const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

	const handleProfileClick = e => { setProfileOpen(e.currentTarget); }
	const handleProfileClose = () => { setProfileOpen(null); }

	return (
		<div className={classes.root}>
      <CssBaseline />
			<AppBar position="fixed" className={classes.appBar}>
        <div className={classes.logoContainer}>
          <img src={ Logo } alt="NaraChips" className={classes.logo} />
        </div>
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.grow} />
          <Typography variant="button" display="block" className={classes.branch}>
            { branchName }
          </Typography>
          <IconButton
						color="inherit"
						aria-controls="profile-menu" 
						aria-haspopup="true"
						onClick={ handleProfileClick }
						className={ classes.profileButton }
					>
						<Avatar src={ profile } />
					</IconButton>
					<ProfileMenu anchorEl={ profileOpen } onClose={ handleProfileClose } img={ profile } />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
          >
            <MenuItems />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="js">
          <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open>
          	
            <MenuItems />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Routes />
      </main>
		</div>
	);
}

export default Component;