import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';

import Module from './../../_presentationals/Module';
import MigrationPdf from './migration.pdf';

import MigrationForm from './migration.form';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', sort: 'date' }, 
	{ label: 'Folio', key: 'folio', align: 'center', sort: 'text', filter: true },
  { label: 'Usuario', key: 'user' },
  { label: 'Origen', key: 'branch' },
  { label: 'Destino', key: 'migrationBranch' }
];


const Component = ({ getData, ...props }) => {
	const [ values, setValues ] = useState([]);
  const [ products, setProducts ] = useState([]);
  const [ openMigrationForm, setOpenMigrationForm ] = useState(false);

  useEffect(() => {
    recordsServices.get('products?inventory=true')
      .then(data => {
        const tmp = data.map(el => ({ value: el.id, label: el.name, unit: unitLabel(el.unit) }));
        setProducts(tmp);
      })
      .catch(e => console.log(e.message));
  }, []);

  useEffect(() => {
    getData && recordsServices.get('migrations')
      .then(data => {
        setValues(data);
        props.getDataFromServer();
      })
      .catch(e => console.log(e.message));
  }, [getData]); // eslint-disable-line react-hooks/exhaustive-deps


  const create = () => setOpenMigrationForm(true);
  const closeMigrationForm = () => { setOpenMigrationForm(false); }
  
  const handleReport = (e,{id}) => MigrationPdf(id);
  
	return (
    <Fragment>
  		<Module 
  			route='migrations'
        title='Traspasos'
        singular='Traspaso'
        Rows={Rows}
        records={values}
        actionCreate={create}
        noEdit={true}
        noDelete={true}
        actions={[
          { icon: <PictureAsPdfIcon/>, label: 'Reporte', onClick: handleReport }
        ]}
      />
      <MigrationForm 
        isOpen={openMigrationForm} 
        handleClose={closeMigrationForm}
        products={products}
      />
    </Fragment>
	);
}


function unitLabel(value) {
  switch(value) {
    case 1:
      return 'PIEZA(S)';
    case 2:
      return 'GRAMO(S)';
    case 3:
      return 'MILILITRO(S)';
    default: return 'NO DEFINIDO';
  }
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);