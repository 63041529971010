import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';

import Module from './../../_presentationals/Module';

const Rows = [
	{ label: 'Nombre', key: 'name', sort: 'text', filter: true }
];

const cashOptions = [
	{ value: true, label: 'SI' },
	{ value: false, label: 'NO' },
]

const Component = ({ getData, ...props }) => {
	const [ values, setValues ] = useState([]);
	
	useEffect(() => {
		getData && recordsServices.get('paymentMethods')
			.then(data => {
        setValues(data);
        props.getDataFromServer(data);
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Module 
			route='paymentMethods'
      title='Métodos de Pago'
      singular='Método de Pago'
      Rows={Rows}
      records={values}
      noDelete={true}
      fields={[
      	{ name: 'name', label: 'Nombre' },
      	{ name: 'isCash', label: 'Manja Efectivo', field: 'select', options: cashOptions }
      ]}
    />
	);
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);