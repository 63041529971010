import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';
import { statesSelectField } from './../../_helpers';

import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import RoomIcon from '@material-ui/icons/RoomOutlined';
import BusinessIcon from '@material-ui/icons/BusinessOutlined';

import Module from './../../_presentationals/Module';

const Rows = [
	{ label: 'Nombre', key: 'name', sort: 'text', filter: true }, 
  { label: 'Teléfono', key: 'phone' },
	{ label: 'Dirección', key: 'address' }
];

const tmpCountry = [{ value: 1, label: 'México' }];
const tmpStates = statesSelectField(1);

const Component = ({ getData, ...props }) => {
	const [ values, setValues ] = useState([]);
	
	useEffect(() => {
		getData && recordsServices.get('branches')
			.then(data => {
				setValues(data);
        props.getDataFromServer();
			})
			.catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Module 
			route='branches'
      title='sucursales'
      singular='Sucursal'
      Rows={Rows}
      records={values}
      fields={[
      	{ icon: <BusinessIcon />, name: 'name', label: 'Nombre', field: 'text' },
      	{ icon: <PhoneIcon />, name: 'phone', label: 'Teléfono', field: 'text' },
        { icon: <RoomIcon />, name: 'addressLine1', label: 'Calle y Número', field: 'text' },
        { name: 'addressLine2', label: 'Colonia', field: 'text' },
        { name: 'city', label: 'Ciudad', field: 'text' },
        { name: 'zipCode', label: 'CP', field: 'text' },
        { name: 'reference', label: 'Referencia', field: 'text' },
        { name: 'countryId', label: 'País', field: 'select', options: tmpCountry },
        { name: 'stateId', label: 'Estado', field: 'select', options: tmpStates },
        { name: 'description', label: 'Descripción', field: 'text' }
      ]}
    />
	);
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);