import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import FormDialog from './../FormDialog';
import TextField from './../Form/TextField';
import SelectField from './../Form/SelectField';

import { closeActionDialog, getDataFromServer, alertActions } from './../../_actions';
import { recordsServices } from './../../_services';
import { errorFormat } from './../../_helpers';

import routes from './../Module/routes';

const ERROR_MESSAGE = "Ha ocurrido un error, por favor inténtalo de nuevo más tarde.";

const Component = props => { 

	const [ values, setValues ] = useState({});
  const [ errors, setErrors ] = useState({});

  useEffect(() => {
    Object.keys(props.openForm[1]).length > 0 && setValues(props.openForm[1]);
  }, [props.openForm]);
  
	const handleChange = e => {
		const name = e.target.name;
		const value = e.target.value;
		setValues(values => ({ ...values, [name]: value }));
	}

  const handleCloseForm = () => {
    props.handleCloseForm();
    setValues({});
    setErrors({});
  }

  const handleAction = () => {
    const requestMethod = !values.id ? "create" : "update";
    const url = routes(props.route, requestMethod.toUpperCase(), { id: values.id, ...values, ...props.params });
    recordsServices[requestMethod](url, values)
      .then(data => {
        if(data.errorCode) { throw new Error(JSON.stringify(data.errorMessage)); }
        props.showAlert({ variant: 'success' })
        props.getDataFromServer();
        handleCloseForm();
      }) //FALTA PROGRAMAR ERROR EN TEXTFIELDS
      .catch(e => { 
        const tmpErrors = errorFormat(e.message);
        setErrors(tmpErrors);
        props.showAlert({ variant: 'warning', message: ERROR_MESSAGE })
      });
  }
  
	return (
		<FormDialog
      title={`${!values.id ? 'Crear' : 'Actualizar'} ${props.singular}`}
      actionLabel={values.id && "Actualizar"}
      isOpen={props.openForm[0]}
      handleClose={handleCloseForm}
      handleAction={handleAction}
    >
    	{ 
    		props.fields && props.fields.map(el => {
          
          if(Object.keys(values).find(key => key === el.noShow)) {
            return false;
          }

    			switch(el.field){
    				case 'select': return (
    					<SelectField 
    						key={el.name}
								name={el.name}
								label={el.label}
                icon={el.icon}
								value={(['boolean', 'number', 'string'].includes(typeof values[el.name]) && el.options.length > 0) ? values[el.name] : ''}
								onChange={handleChange}
								options={el.options}
                error={errors[el.name]}
							/>
    				)
    				default: return (
  						<TextField 
  							type={el.type || 'text'}
  							key={el.name}
								icon={el.icon}
								name={el.name}
								label={el.label}
								value={values[el.name] || ''}
								onChange={handleChange}
                error={errors[el.name]}
							/>
  					)
    			}

    		})
    	}
    </FormDialog>
	)
}

const mapStateToProps = states => ({
  openForm: states.actionDialog
});

const mapDispatchToProps = dispatch => ({
  handleCloseForm: () => dispatch(closeActionDialog()),
  getDataFromServer: () => dispatch(getDataFromServer()),
  showAlert: data => dispatch(alertActions.show(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);