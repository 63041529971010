import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import { isMobile } from "react-device-detect";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import Hidden from '@material-ui/core/Hidden';

import AddCircleIcon from '@material-ui/icons/AddOutlined';
import EventIcon from '@material-ui/icons/EventOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVertOutlined';

import TextField from './../../Form/TextField';
import IconButton from './../../Form/IconButton';

import { 
  openActionDialog, 
  openFilterDialog, 
  editSearchValue, 
  getDataFromServer 
} from './../../../_actions';
import History from './../../../_helpers/history';

import Menu from './menu';

import useStyles from './style';

const Component = props => {

  const classes = useStyles();

  const [ openMenu, setOpenMenu ] = useState(null);

  const handleOpenMenu = e => { setOpenMenu(e.currentTarget); }
  const handleCloseMenu = () => { setOpenMenu(null); }
  const handleOpenCreate = () => { props.openForm(); }
  const handleOpenFilter = () => { props.openFilter(); }
  const handleSearch = e => { props.editSearchValue(e.target.value); }
  const handleRedirect = e => { 
    History.push(props.redirect); 
    props.getDataFromServer();
  }

  return (
    <Fragment>
    <Grid container alignItems="center" className={ classes.header } spacing={0}>
      <Grid item md={7} xs={6}>
        <Typography variant="button" display="block">
          { props.title && props.title.toUpperCase() }  ({ props.qty || 0 })
          <br/> <small>{ props.subTitle || "" }</small>
        </Typography>
      </Grid>
      
      <Grid container item justify="flex-end" md={5} xs={6}>
        { !isMobile &&<TextField 
          label="Búsqueda"
          variant="outlined"
          margin="dense"
          size="small"
          color="secondary"
          fullWidth={ false }
          onChange={handleSearch}
          className={ classes.marginRight }
          noFormat
        />
        }
        {
          !props.noCreate && (
            <IconButton 
              color="secondary" 
              label="Crear" 
              action={ props.actionCreate || handleOpenCreate } 
              className={ classes.paddingX }
              icon={<AddCircleIcon />}
            />
          )
        }
        { props.filter &&
          <IconButton 
            label="Filtrar" 
            action={ handleOpenFilter } 
            className={ classes.paddingX }
            icon={<EventIcon />}
          />
        }
        <IconButton 
          label="Más Opciones" 
          action={ handleOpenMenu } 
          className={ classes.paddingX }
          aria-controls="module-menu" 
          aria-haspopup="true" 
          icon={<MoreVertIcon />}
        />
        <Menu redirect={ props.redirect && handleRedirect } anchorEl={ openMenu } onClose={ handleCloseMenu } />
      </Grid>
      
    </Grid>

    <div className={classes.toolbar} />
    </Fragment>
  )
}

const mapDispatchToProps = dispatch => ({
  openForm: () => dispatch(openActionDialog({})),
  openFilter: () => dispatch(openFilterDialog({})),
  editSearchValue: value => dispatch(editSearchValue(value)),
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(null, mapDispatchToProps)(Component);