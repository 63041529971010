import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import { recordsServices } from './../../_services';
import { getDataFromServer } from './../../_actions';
import { queryFormat } from './../../_helpers/general';
import moneyFormat from './../../_helpers/moneyFormat';

import Module from './../../_presentationals/Module';

const Rows = [
	{ label: 'Fecha', key: 'date', sort: 'date', filter: true },
	{ label: 'Producto', key: 'product', sort: 'text', filter: true },
	{ label: 'Precio', key: 'unitPrice', align: 'right', format: 'currency' },
	{ label: 'Cant.', key: 'qty', align: 'center' },
	{ label: 'Total', key: 'total', align: 'right', format: 'currency' },
	{ label: 'Modalidad', key: 'modality', sort: 'text' },
	{ label: 'Usuario', key: 'user', sort: 'text', filter: true },
	{ label: 'Sucursal', key: 'branch', sort: 'text', filter: true }
];

const Component = ({ getData, ...props }) => {
	const [ values, setValues ] = useState([]);
	const [ users, setUsers ] = useState([]);
	const [ products, setProducts ] = useState([]);
	const [ amount, setAmount ] = useState(0);
	
	useEffect(() => {
		getData && recordsServices.get(`products/movements`)
			.then(data => {
				const tmp = data.map(el => ({
					...el, date: format(new Date(el.date),'yyyy/MM/dd hh:mm')
				}));
				const total = data.reduce((total = 0,el) => (parseFloat(total) + parseFloat(el.total)),0);
				setValues(tmp);
				setAmount(total);
        props.getDataFromServer();
			})
			.catch(e => console.log(e.message));
		getData && recordsServices.get('users')
      .then(data => {
        const tmp = data.map(el => ({ value: el.id, label: el.name }));
        setUsers(tmp);
      })
      .catch(e => console.log(e.message));
    getData && recordsServices.get('products')
      .then(data => {
        const tmp = data.map(el => ({ value: el.id, label: el.name }));
        setProducts(tmp);
      })
      .catch(e => console.log(e.message));
	}, [getData]); // eslint-disable-line react-hooks/exhaustive-deps
	
	const handleFilter = val => {
		const { 
			start = format(new Date(),'yyyy/MM/dd'), 
			finish = format(new Date(),'yyyy/MM/dd'), 
			user = { value: null },
			product = { value: null } 
		} = val;
		recordsServices.get(`products/movements?${queryFormat({ start, finish, userId: user.value, productId: product.value })}`)
		.then(data => {
			const tmp = data.map(el => ({
				...el, date: format(new Date(el.date),'yyyy/MM/dd hh:mm')
			}));
			const total = data.reduce((total = 0,el) => (parseFloat(total) + parseFloat(el.total)),0);
			setValues(tmp);
			setAmount(total);
		})
		.catch(e => console.log(e.message));
	};

	return (
		<Module 
			route='products/movements'
      title='Reporte de Movimiento Por Producto'
      singular='Movimiento Por Producto'
      subTitle={`Total: $ ${moneyFormat(amount)}`}
      Rows={Rows}
      records={values}
      noCreate={true}
      noDelete={true}
      noEdit={true}
      filter={[handleFilter,
      	[
					{ field: 'date', label: 'Inicio', name: 'start' },
					{ field: 'date', label: 'Fin', name: 'finish' },
					{ field: 'auto', label: 'Producto', name: 'product', options: products },
					{ field: 'auto', label: 'Usuario', name: 'user', options: users }
				]
      ]}
    />
	);
}

const mapStateToProps = state => ({
  getData: state.getData
});

const mapDispatchToProps = dispatch => ({
  getDataFromServer: () => dispatch(getDataFromServer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);